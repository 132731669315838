import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store.js'

export const use_calendar_slot_areas = defineStore('calendar_slot_areas', {
  state: () => ({
    active: [],
  }),

  actions: {
    async update_area_color({ service_id, area_id, color }) {
      const url = `${use_smcb_gym().base_url}/services/${service_id}/checkins/areas/${area_id}/update-color`
      const { data } = await Vue.smcb_axios.patch(url, { color })
      const ix = this.active.findIndex(a => a.id == data.area.id)
      Vue.set(this.active, ix, data.area)
    },
  },

  getters: {
    from_id: state => area_id => {
      return state.active?.find(a => a.id === area_id)
    },
  },
})
