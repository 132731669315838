import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_calendar_employees } from '@core/entry_point/stores/calendar_employees_store'
import { use_entry_point } from '@core/entry_point/stores/entry_point_store'
import { use_task_groups } from '@core/entry_point/stores/task_groups_store'
import { use_work_categories } from '@core/entry_point/stores/work_categories_store'

function prepare_gym_user(user, use) {
  let employee = use.calendar_employees.from_gym_user_id(user.id)
  const work_categories = use.work_categories.active
  const task_groups = use.task_groups.active

  if (!user || !employee) return
  user = JSON.parse(JSON.stringify(user))
  employee = JSON.parse(JSON.stringify(employee))
  user.employee = employee

  if (employee) {
    const wcs = work_categories.filter(category => use.work_categories.is_qualified_employee_for_work_category(category.id, employee.id))
    const tgs = task_groups.filter(task_group => use.task_groups.is_qualified_employee_for_task_group(task_group.id, employee.id))
    const grouped_work_categories = {}

    wcs.forEach(work_category => {
      const assigned = tgs.filter(task_group => task_group.work_category_id === work_category.id)
      const total = task_groups.filter(task_group => task_group.work_category_id === work_category.id).length

      grouped_work_categories[work_category.id] = {
        work_category_name: work_category.name,
        task_group_names: assigned.map(tg => tg.name),
        assigned: assigned.length,
        total,
        percentage: total <= 0 ? 0 : assigned.length / total,
      }
    })

    if (employee.latest_task) {
      employee.latest_task.start = Vue.$vl_time.parse_as_local(employee.latest_task.start)
      employee.latest_task.end = Vue.$vl_time.parse_as_local(employee.latest_task.end)
    }

    if (employee.latest_work_log) {
      employee.latest_work_log.start = Vue.$vl_time.parse_as_local(employee.latest_work_log.start)
      employee.latest_work_log.end = Vue.$vl_time.parse_as_local(employee.latest_work_log.end)
    }

    if (employee.latest_task && employee.latest_work_log) {
      if (employee.latest_task.end > employee.latest_work_log.end) {
        employee.latest_assignment = {
          type: 'Task',
          assignment: employee.latest_task,
        }
      } else {
        employee.latest_assignment = {
          type: 'WorkLog',
          assignment: employee.latest_work_log,
        }
      }
    } else if (employee.latest_task) {
      employee.latest_assignment = {
        type: 'Task',
        assignment: employee.latest_task,
      }
    } else if (employee.latest_work_log) {
      employee.latest_assignment = {
        type: 'WorkLog',
        assignment: employee.latest_work_log,
      }
    }

    const assignments_by_work_category = {}

    for (const [type, key] of [
      ['WorkLog', 'latest_work_logs_by_work_category'],
      ['Task', 'latest_tasks_by_work_category'],
    ]) {
      for (const assignment of employee[key]) {
        assignment.start = Vue.$vl_time.parse_as_local(assignment.start)
        assignment.end = Vue.$vl_time.parse_as_local(assignment.end)
      }

      for (const assignment of employee[key]) {
        const wc_id = assignment.work_category_id
        assignments_by_work_category[wc_id] = assignments_by_work_category[wc_id] || []
        assignments_by_work_category[wc_id].push({
          type,
          assignment,
        })
      }
    }

    employee.latest_assignments_by_work_category = Object.entries(assignments_by_work_category).reduce((acc, [wc_id, assignments]) => {
      acc[wc_id] = assignments.sort((a, b) => b.end - a.end)[0]
      return acc
    }, {})

    user.work_categories = wcs
    user.task_groups = tgs
    user.grouped_work_categories = grouped_work_categories
  }

  return user
}

export const use_staff_users = defineStore('staff_users_store', {
  state: () => ({
    gym_users: [],
  }),

  actions: {
    add(gym_user) {
      this.gym_users.push(gym_user)
    },

    async update_gym_user(gym_user) {
      const { data } = await Vue.smcb_axios.patch(`${this.base_fred_url}/gym_users/${gym_user.id}`, gym_user)
      if (data) {
        this.gym_users = this.gym_users.filter(gu => gu.id !== data.id)
        this.remove(data.id)
        this.add(data)
      }
    },

    remove(gym_user_id) {
      this.gym_users = this.gym_users.filter(gu => gu.id !== gym_user_id)
    },

    async delete_gym_user(gym_user) {
      const { data } = await Vue.smcb_axios.delete(`${this.base_fred_url}/gym_users/${gym_user.id}`)
      this.remove(data.id)
    },
  },

  getters: {
    base_fred_url: () => {
      return `/${window.VL_CURRENT_APP}/${use_entry_point().gym.slug}/staff`
    },

    user_exists_by_email: state => email => state.gym_users.some(gu => gu.user && gu.user.email === email),

    gym_user_by_id: state => id => {
      return state.gym_users.find(gu => gu.id === id)
    },

    prepared_gym_users(state) {
      const use = {
        task_groups: use_task_groups(),
        work_categories: use_work_categories(),
        calendar_employees: use_calendar_employees(),
      }
      const gym_users = state.gym_users.map(gu => prepare_gym_user(gu, use))

      const is_valid_prepared_user = user => {
        if (!user || !user.id || !user.employee) return false
        if (user.vl_employee) return false
        return user.status !== 'removed'
      }

      return gym_users.filter(is_valid_prepared_user)
    },

    prepare_gym_user: () => user => {
      const use = {
        task_groups: use_task_groups(),
        work_categories: use_work_categories(),
        calendar_employees: use_calendar_employees(),
      }

      return prepare_gym_user(user, use)
    },
  },
})
