import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'
import { use_work_categories } from './work_categories_store'

function parse_business_unit(business_unit) {
  return Object.freeze(business_unit)
}

export const use_business_units = defineStore('business_units', {
  state: () => ({
    all: [],
    show_all_work_categories: false,
  }),

  actions: {
    set_business_units(business_units) {
      this.all = business_units.map(parse_business_unit)
    },

    async create(business_unit) {
      const { data } = await Vue.smcb_axios.post(this.base_url, business_unit)
      this.set_business_units([...this.all, data.business_unit])

      use_work_categories().all = [...use_work_categories().all, ...data.work_categories]

      return data.business_unit.id
    },

    async update(business_unit) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url}/${business_unit.id}`, business_unit)

      this.all = this.all.map(t => {
        if (t.id !== data.id) {
          return t
        } else {
          return parse_business_unit(data)
        }
      })
    },

    async delete(business_unit) {
      await Vue.smcb_axios.delete(`${this.base_url}/${business_unit.id}`)
      this.all = this.all.filter(b => b.id !== business_unit.id)
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/businessunits`,

    active: state => {
      const locale = I18n.locale || 'en'
      return state.all.filter(bu => !bu.archived_at).sort((a, b) => a.name.localeCompare(b.name, locale, { sensitivity: 'base' }))
    },

    from_id: state => id => state.all.find(t => t.id === id),
  },
})
