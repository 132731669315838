import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'

function parse_business_days(business_days) {
  return Object.freeze({
    ...business_days,
    created_at: Vue.$vl_time.parse_as_local(business_days.created_at),
    active_from: Vue.$vl_time.parse_as_local(business_days.active_from),
    active_until: business_days.active_until ? Vue.$vl_time.parse_as_local(business_days.active_until) : null,
  })
}

export const use_business_days = defineStore('business_days', {
  state: () => ({
    active: [],
  }),

  actions: {
    async fetch_active_business_days() {
      const response = await Vue.smcb_axios.get(`${this.base_url}/active`)

      if (response.status === 200) {
        this.active = response.data.days
      }
    },

    async fetch_business_days({ page, page_size }) {
      const response = await Vue.smcb_axios.get(this.base_url, { page, page_size })

      return {
        page,
        business_days: response.data.map(parse_business_days),
        count: Number(response.headers.get('Business-Days-Count')),
        page_size: Number(response.headers.get('Business-Days-Page-Size')),
      }
    },

    async create_business_days(payload) {
      const { data } = await Vue.smcb_axios.post(this.base_url, payload)
      this.active = data.days
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/business_days`,
    has_active_business_days: state => !!state.active.length,
  },
})
