import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_calendar_employees } from './calendar_employees_store'
import { use_entry_point } from './entry_point_store'
import { use_staff_users } from './staff_users_store'

// NOTE: stateless store
export const use_invitations = defineStore('invitations', {
  actions: {
    async invite_gym_user({ email, firstname, lastname, message }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_fred_url}`, { email, firstname, lastname, message })
      use_calendar_employees().add(data.employee)
      use_staff_users().add(data.gym_user)

      return data
    },

    async update_invitation_email(gym_user, email) {
      const { data } = await Vue.smcb_axios.patch(`${this.base_fred_url}/${gym_user.id}`, { email })
      use_calendar_employees().update(data.employee)
    },
  },

  getters: {
    base_fred_url: () => `/${window.VL_CURRENT_APP}/${use_entry_point().gym.slug}/staff/invitations`,
  },
})
