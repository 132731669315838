import { defineStore } from 'pinia'
import Vue from 'vue'

// import { use_entry_point } from './entry_point_store'

function parse_message(message) {
  message.updated = Vue.$vl_time.parse_as_local(message.updated)
  return message
}

export const use_hermes_messages = defineStore('hermes_messages', {
  state: () => ({
    messages: [],
    gym: {},
    loading: true,
  }),

  actions: {
    async load_user_messages() {
      const { data } = await Vue.smcb_axios.get(`${window.HERMES_API_URL}/site/${window.HERMES_SITE}/room/${this.gym.id}/messages`)
      this.messages = data.messages.map(parse_message)
      this.loading = false
    },

    add(message) {
      this.messages = [...this.messages, parse_message(message)]
    },

    async mark_all_as_read() {
      this.mark_as_read(this.messages.filter(x => !x.viewed))
    },

    async mark_as_read(messages) {
      const message_ids = messages.map(x => x.id)
      await Vue.smcb_axios.post(`${window.HERMES_API_URL}/site/${window.HERMES_SITE}/room/${this.gym.id}/messages/mark-as-read`, { message_ids })

      for (const message_id of message_ids) {
        const message = this.messages.find(m => m.id === message_id)
        if (message) message.viewed = true
      }
    },

    async hide(messages) {
      const message_ids = messages.map(x => x.id)
      const { data } = await Vue.smcb_axios.post(`${window.HERMES_API_URL}/site/${window.HERMES_SITE}/room/${this.gym.id}/messages/hide`, { message_ids })
      this.messages = data.messages.map(parse_message)
    },
  },

  getters: {
    filtered: state => {
      const relevant_titles = [
        'broadcast-message',
        // 'new-booking',
        'task-assigned',
        'task-updated',
        'zlaggable-added',
        'zlaggable-archived',
        'zlaggable-comment',
        'zlaggable-grade-suggestion',
        'zlaggable-updated',
      ]

      return state.messages
        .filter(m => relevant_titles.includes(m.title))
        .filter(m => m.title === 'broadcast-message' || (m.gym_id && parseInt(m.gym_id) === state.gym.id))
    },

    grouped() {
      let origin = this.filtered.slice()
      let result = []

      // loop over all the messages manually
      for (let index = 0; index < origin.length; index++) {
        let message = origin[index]
        message.index = index
        let groupArray = [message]
        let sameMessageType = true

        // while not at the end of the array AND while the type of the messages are the same as the current message
        // keep progressing through the array, and add the messages to a new array
        while (index + 1 < origin.length && sameMessageType) {
          let nextMessage = origin[index + 1]
          if (
            ['zlaggable-comment', 'zlaggable-grade-suggestion', 'zlaggable-archived', 'zlaggable-added', 'new-booking'].includes(message.title) &&
            message.title === nextMessage.title &&
            message.viewed === nextMessage.viewed
          ) {
            if (
              ['zlaggable-comment', 'zlaggable-grade-suggestion', 'zlaggable-archived', 'zlaggable-added'].includes(message.title) &&
              message.extras.type !== nextMessage.extras.type
            ) {
              sameMessageType = false
            } else {
              index++
              nextMessage.index = index
              groupArray.push(nextMessage)
            }
          } else {
            sameMessageType = false
          }
        }

        if (groupArray.length > 1) {
          result.push({
            title: `grouped-${message.title}`,
            messages: groupArray,
            viewed: groupArray.every(m => m.viewed),
          })
        } else {
          result.push(groupArray[0])
        }
      }

      return result
    },

    unread_grouped_count() {
      let count = 0

      this.grouped
        .filter(message => message.viewed === false)
        .forEach(message => {
          if (
            ['grouped-zlaggable-comment', 'grouped-zlaggable-grade-suggestion', 'grouped-zlaggable-archived', 'grouped-zlaggable-added'].includes(message.title)
          ) {
            if (message.messages.some(m => m.viewed === false && m.extras.type === 'GymRoute')) {
              count++
            }
            if (message.messages.some(m => m.viewed === false && m.extras.type === 'GymBoulder')) {
              count++
            }
          } else {
            count++
          }
        })
      return count
    },

    archive_link: state => (type, locale) => {
      if (type === 'GymRoute') {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/routes/archive`
      } else {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/boulders/archive`
      }
    },

    calendar_link: state => (messageProps, locale) => {
      return `/${locale}/gyms/${state.gym.slug}/calendar`
      // return `/${locale}/gyms/${state.gym.slug}/calendar#/${messageProps.id}`
    },

    routesetting_link: state => (messageProps, locale) => {
      if (messageProps.type === 'GymRoute') {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/routes#/${messageProps.id}`
      } else {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/boulders#/${messageProps.id}`
      }
    },

    routesetting_details_link: state => (messageProps, locale, details) => {
      if (messageProps.type === 'GymRoute') {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/routes#/${messageProps.id}/${details}`
      } else {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/boulders#/${messageProps.id}/${details}`
      }
    },

    routesetting_link_multiple: state => (messages, locale) => {
      let ids = messages.map(m => m.extras.id).toString()
      if (messages[0].extras.type === 'GymRoute') {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/routes#/${ids}`
      } else {
        return `/${locale}/gyms/${state.gym.slug}/routesetting/boulders#/${ids}`
      }
    },
  },
})
