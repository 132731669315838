import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_entry_point } from './entry_point_store'

export const use_agreements = defineStore('agreements', {
  state: () => ({
    on_db: null,
  }),

  actions: {
    async accepted(name) {
      const entry_point = use_entry_point()
      const { data } = await Vue.smcb_axios.post(`/gyms/${entry_point.gym.slug}/agreements/${name}`)
      this.on_db = data.agreements
    },
  },

  getters: {
    names: state => state.on_db.reduce((sum, a) => ({ ...sum, [a]: true }), {}),
  },
})
