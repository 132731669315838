import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

export const use_participant_alt_keys = defineStore('participant_alt_keys', {
  state: () => ({
    per_participant: {},
    participant_coords_for_alt_key: {},
  }),

  actions: {
    //
    // See if there is a participant in this location linked to an alt key
    //
    async search_participant_for_alternative_key({ alt_key }) {
      // Skip doing a backend request if we have the answer locally
      const preexisting = this.get_participant_coords_for_alt_key(alt_key)
      if (preexisting) return preexisting

      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/checkins/participant/with-alt-key/${alt_key}`
      const { data } = await Vue.smcb_axios.get(url)
      const participant_id = data.participant_id || null
      const prefix = data.prefix || null

      if (!participant_id) return null
      Vue.set(this.participant_coords_for_alt_key, alt_key, { participant_id, prefix })

      // Return coords for fetching participant on Quick Result mode
      return { participant_id, prefix }
    },

    //
    // Fetch all alternative keys associated with a participant
    //
    async fetch_alternative_keys_for_participant({ participant_id }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/checkins/participants/${participant_id}/alt-keys`
      const { data } = await Vue.smcb_axios.get(url)
      Vue.set(this.per_participant, participant_id, data.alt_keys)
    },

    //
    // Create a new alternative key for a certain participant (e.g. link QR code)
    //
    async add_alternative_key_for_participant({ participant_id, alt_key }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/checkins/participants/${participant_id}/alt-keys`
      const { data } = await Vue.smcb_axios.post(url, { alt_key })
      Vue.set(this.per_participant, participant_id, data.alt_keys)
    },
  },

  getters: {
    get_participant_coords_for_alt_key: state => alt_key => state.participant_coords_for_alt_key[alt_key] || null,
  },
})
