import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_reservations } from './reservations_store'
import { use_smcb_gym } from './smcb_gym_store'
import { use_task_bookings } from './task_bookings_store'
import { use_tickets } from './tickets_store'
import localisation_options from '@core/lib/localisation_options'

const default_currency_format = localisation_options.currency_format.find(c => c.text === 'EUR')

export const use_payments = defineStore('payments', {
  state: () => ({
    merchant_data: null,
    payment_integration: null,
    dashboard_url: '',
    receipt_preview_url: '',
    onboarding_url: '',
    suggested_currency: null,
  }),

  actions: {
    async load() {
      const tickets = use_tickets()

      let success = false
      try {
        const { data } = await Vue.smcb_axios.get(this.base_url)
        if (data) {
          this.merchant_data = data.merchant_data
          this.payment_integration = data.payment_integration
          tickets.set(data.tickets)
          this.dashboard_url = data.login_link
          this.receipt_preview_url = data.receipt_preview_url
          this.onboarding_url = data.onboarding_url

          if (data.merchant_data.commission_tier_set !== true) {
            const { data } = await Vue.smcb_axios.get(`${this.base_url}/suggested_currency`)
            if (data.suggested_currency) this.suggested_currency = data.suggested_currency
          }

          success = true
        }
      } catch (e) {
        console.error(e)
      }
      return success
    },

    async update_merchant_data(merchant_data) {
      const response = await Vue.smcb_axios.patch(`${this.base_url}/merchant`, merchant_data)
      if (response.status === 200 && response.data) {
        this.merchant_data = response.data
      }
    },

    async reset_onboarding() {
      const response = await Vue.smcb_axios.patch(`${this.base_url}/reset_onboarding`)
      if (response.status === 200 && response.data) {
        this.merchant_data = response.data.merchant_data
      }
    },

    // TODO: Check if in use
    async toggle_payments_status(status) {
      const response = await Vue.smcb_axios.patch(`${this.base_url}/toggle_status`, { status: status })
      if (response.status === 200 && response.data) {
        this.payment_integration = response.data
      }
    },

    async load_booking_payment_data(booking) {
      const response = await Vue.smcb_axios.get(`${this.base_url}/bookings/${booking.id}`)
      if (response.status === 200 && response.data) {
        return response.data
      }
    },

    async is_refundable(booking) {
      const response = await Vue.smcb_axios.get(`${this.base_url}/refundable/${booking.id}`)

      if (response.status === 200 && response.data) {
        return response.data.is_refundable
      }

      return false
    },

    async confirm_currency_suggestion() {
      const response = await Vue.smcb_axios.post(`${this.base_url}/confirm_currency_suggestion`)
      if (response.status === 200 && response.data) {
        this.merchant_data = response.data
      }
    },

    async update_merchant_application_fee_vat(vat) {
      const response = await Vue.smcb_axios.post(`${this.base_url}/merchant_application_fee_vat`, { vat })
      if (response.status === 200 && response.data) {
        this.merchant_data = response.data
      }
    },

    async opt_in() {
      const response = await Vue.smcb_axios.post(`${this.base_url}/opt_in`)
      if (response.status === 200 && response.data) {
        this.merchant_data = response.data
      }
    },

    async refund_participants_items({ booking_id, participant_ids, item_ids, refund_amount }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/refund_participants_items`, { booking_id, participant_ids, item_ids, refund_amount })
      return data
    },

    async refund_booking_payment(booking) {
      const response = await Vue.smcb_axios.patch(`${use_smcb_gym().base_url}/payments/refund_booking_payment/${booking.id}`)
      if (response.status === 200 && response.data) {
        if (booking.bookable_type === 'Slot') {
          use_reservations().update_slot_booking(response.data)
        } else if (['Task', 'RecurringTask'].includes(booking.bookable_type)) {
          const task_bookings = use_task_bookings()
          task_bookings.update_calendar_booking(response.data, { root: true })
          return task_bookings.bookings.find(b => b.id === booking.id)
        }
        return response.data
      }
    },

    async desk_booking_payment(booking) {
      let response = await Vue.smcb_axios.post(`${use_smcb_gym().base_url}/payments/desk_booking_payment/${booking.id}`)
      if (response.status === 200 && response.data) {
        const task_bookings = use_task_bookings()
        task_bookings.update_calendar_booking(response.data, { root: true })
        return task_bookings.bookings.find(b => b.id === booking.id)
      }
    },
  },

  getters: {
    currency_format: state => {
      const smcb_gym = use_smcb_gym()

      if (state.merchant_data && state.merchant_data.currency_code) {
        const currency_format = localisation_options.currency_format.find(c => c.text === state.merchant_data.currency_code)
        if (currency_format) return currency_format
      }

      if (smcb_gym.settings && smcb_gym.settings.currency_format) {
        return smcb_gym.settings.currency_format
      }

      return default_currency_format
    },

    formatted_ticket_price() {
      const currency_format = this.currency_format
      return price => Vue.localisation.format_price({ currency_format }, price)
    },

    formatted_vat: state => {
      return vat => Vue.$vl_utils.decimals_to_percentage(vat, 2).toFixed(2)
    },

    base_url: state => {
      const smcb_gym = use_smcb_gym()
      return `${smcb_gym.base_url}/payments`
    },

    onboarding_complete: state => state.merchant_data && state.merchant_data.onboarding_status === 'onboarded',

    application_fee_vat: state => state.merchant_data?.application_fee_vat,
  },
})
