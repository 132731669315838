import { defineStore } from 'pinia'
import copy_paste_mixin from './copy_paste_mixin'
import editable_mixin from './editable_mixin'
import Vue from 'vue'
import { use_entry_point } from './entry_point_store'
import { use_services } from '@core/entry_point/stores/services_store'

const rectangle_properties = { fill: '#F0F4F8', stroke: '#18ACC0', strokeWidth: 0.5, opacity: 0.75 }
const document_editor_cache_key = `document_editor_fields`

export const use_pdf_editor = defineStore('pdf_editor', {
  state: () => ({
    ...editable_mixin.state(),
    ...copy_paste_mixin.state(document_editor_cache_key),

    selected_tool: null,
    mode: null,
    edit_history: [],
    modes: Object.freeze({
      drag: 'drag',
      select: 'select',
    }),
    fields: {},
    dragged_field: null,
    styles: {
      align: 'left',
      vertical_align: 'middle',
      font_size: 10,
      font_style: 'italic',
    },
    multiselect: [],
    page_size: {},
  }),

  actions: {
    ...editable_mixin.actions(),
    ...copy_paste_mixin.actions(),

    set_shapes(shapes) {
      this.update_edit(shapes.map((s, idx) => this.build_shape(s, idx)))
      this.edit_history = [structuredClone(this.edit)]
    },

    build_shape(shape, id) {
      let style = null

      if (['field', 'textbox'].includes(shape.type)) {
        style = this.styles
      }

      return {
        ...style,
        ...shape,
        selected: false,
        id: String(id),
      }
    },

    save_history() {
      this.edit_history.push(structuredClone(this.edit))
    },

    add_shape(shape) {
      const shapes = this.edit
      const max_id = shapes.length > 0 ? Math.max(...shapes.map(s => parseInt(s.id))) : 0
      const build_shape = this.build_shape(shape, max_id + 1)
      this.edit.push(build_shape)
      this.save_history()
      return build_shape
    },

    add_signature(signature) {
      return this.add_shape({
        ...rectangle_properties,
        ...signature,
        width: 200,
        height: 42,
        type: 'signature',
      })
    },

    add_checkbox(checkbox) {
      return this.add_shape({
        ...rectangle_properties,
        ...checkbox,
      })
    },

    add_textbox(textbox) {
      return this.add_shape({
        ...rectangle_properties,
        ...textbox,
        type: 'textbox',
      })
    },

    update_shape(shape) {
      const idx = this.edit.findIndex(s => s.id === shape.id)
      Vue.set(this.edit, idx, structuredClone(shape))
      this.save_history()
    },

    remove_shape(shape_id) {
      const shape_idx = this.edit.findIndex(s => s.id === shape_id)
      this.edit.splice(shape_idx, 1)
      this.save_history()
    },

    copy_shape(shape_id) {
      const shape = this.edit.find(s => s.id === shape_id)
      const max_id = Math.max(...this.edit.map(s => parseInt(s.id)))

      let new_shape_data = { ...structuredClone(shape), x: shape.x + 10, y: shape.y + 10 }

      if (['checkbox', 'textbox'].includes(shape.type)) {
        const items = this.edit.filter(e => e.type === shape.type)
        const max_field_id = items.length > 0 ? Math.max(...items.map(c => parseInt(c.field_id))) : 0
        new_shape_data.field_id = max_field_id + 1
      }

      const new_shape = this.build_shape(new_shape_data, max_id + 1)

      this.edit.push(new_shape)
      this.save_history()
      return new_shape
    },

    undo() {
      if (!this.can_undo) return

      // throw away the last state
      this.edit_history.pop()

      // then restore the state before
      const new_state = structuredClone(this.edit_history[this.edit_history.length - 1])
      const sanitized_last_state = new_state.map(s => ({ ...s, selected: false }))
      this.update_edit(sanitized_last_state)
    },

    cancel() {
      if (!this.can_undo) return

      //keep the first state
      this.edit_history = this.edit_history.slice(0, 1)

      // then restore the state before
      const new_state = structuredClone(this.edit_history[0])
      const sanitized_last_state = new_state.map(s => ({ ...s, selected: false }))
      this.update_edit(sanitized_last_state)
    },

    localize_field_name(path) {
      const [type, name] = path.split('.')

      switch (type) {
        case 'p':
          if (Vue.$i18n.te(`core.person.${name}`)) {
            return Vue.$i18n.t(`core.person.${name}`)
          } else if (Vue.$i18n.te(`core.${name}`)) {
            return Vue.$i18n.t(`core.${name}`)
          } else if (name === 'tax_id') {
            return Vue.$i18n.t('public_calendar.tax_id.it')
          } else if (Vue.$i18n.te(`public_calendar.${name}`)) {
            return Vue.$i18n.t(`public_calendar.${name}`)
          }
          break
        case 'ef':
          const services = use_services()
          const lang = I18n.locale || 'en'
          const io = Object.values(services.of_selected.integration_objects).find(io => io.key === name)
          if (io) return io.labels[lang] || io.labels.en || io.key
          break
        case 'l':
          if (Vue.$i18n.te(`settings.${name}`)) {
            return Vue.$i18n.t(`settings.${name}`)
          }
          break
      }

      return name
        .replaceAll('_', ' ')
        .split(' ')
        .map(word => word[0].toLocaleUpperCase() + word.substring(1))
        .join(' ')
    },

    localize_template(template) {
      const matches = template.match(/{.*?}/g)
      let field = template

      matches.forEach(match => {
        const ufi = match.replace(/[{}]/g, '')
        field = field.replace(match, this.localize_ufi(ufi))
      })

      return field
    },

    localize_ufi(ufi) {
      const [path, flavour] = ufi.split(':')
      let field = this.localize_field_name(path)

      if (flavour) {
        field += ` (${this.localize_field_flavour(flavour)})`
      }
      return field
    },

    localize_field_flavour(flavour) {
      return Vue.$i18n.t('flavour.' + flavour)
    },

    load_fields() {
      this.fields = {
        participant: [
          { template: '{p.firstname}' },
          { template: '{p.lastname}' },
          { template: '{p.gender}' },
          { template: '{p.birth_city}' },
          { template: '{p.birth_province}' },
          { template: '{p.country}' },
          { template: '{p.city}' },
          { template: '{p.province}' },
          { template: '{p.address}' },
          { template: '{p.zip}' },
          { template: '{p.date_of_birth}' },
          { template: '{p.age}' },
          { template: '{p.phone}' },
          { template: '{p.email}' },
          { template: '{p.id}' },
          { template: '{p.arrival_at}', flavours: { 'p.arrival_at': ['date', 'time', 'date_time'] } },
          { template: '{p.checked_in_at}', flavours: { 'p.checked_in_at': ['date', 'time', 'date_time'] } },
          { template: '{p.checked_out_at}', flavours: { 'p.checked_out_at': ['date', 'time', 'date_time'] } },
        ],

        extra_fields: Object.values(use_services().participant_fields)
          .filter(iobj => !['auto-pass', 'upload-participant-file'].includes(iobj.type))
          .map(iobj => {
            let el = { template: `{ef.${iobj.key}}` }

            if (iobj.data.fields && iobj.data.multiple !== true) {
              let flavours = iobj.data.fields.map(f => f.label)
              flavours.push('text')
              el.flavours = { [iobj.key]: flavours }
            }

            return el
          }),

        location: [
          { template: '{l.name}' },
          { template: '{l.city}' },
          { template: '{l.address}' },
          { template: '{l.zip}' },
          { template: '{l.country}' },
          { template: '{l.email}' },
          { template: '{l.phone}' },
          { template: '{l.logo_path}' },
          { template: '{l.owner}' },
        ],

        combo: [
          { template: '{p.firstname}, {p.lastname}' },
          { template: '{p.lastname}, {p.firstname}' },
          { template: '{p.zip}, {p.city}' },
          { template: '{p.birth_city}, {p.date_of_birth}' },
          { template: '{l.city}, {p.checked_in_at}', flavours: { 'p.checked_in_at': ['date', 'time', 'date_time'] } },
          { template: '{l.zip}, {l.city}' },
          { template: '{l.name}, {l.address} {l.zip} {l.city}' },
        ],
      }

      if (use_entry_point().gym.country_code === 'IT') {
        this.fields.participant.push({ template: '{p.tax_id}' })
      }

      if (Vue.$vl_utils.is_running_noq()) {
        this.fields.location.push(
          { template: '{l.data_protection_officer}' },
          { template: '{l.teststellennr}' },
          { template: '{l.pharmacy_id}' },
          { template: '{l.impfzentrumnr}' }
        )
      }
    },

    easy_clipboard_copy() {
      this.clipboard_copy(this.edit, Object.keys(this.edit))
    },

    easy_clipboard_paste() {
      let contents = this.clipboard_paste()
      if (!contents) return

      contents = this.filter_valid_fields(contents)
      this.set_shapes(contents)
    },

    filter_valid_fields(contents) {
      Object.keys(this.fields).forEach(
        f =>
          (contents = Object.values(contents).filter(
            c => c.kind !== f || (c.kind === f && this.fields[f].map(t => t.template).includes(c.field_id.replace(/:.*/, '}')))
          ))
      )
      return contents
    },
  },

  getters: {
    can_undo() {
      return this.edit_history.length > 1
    },
  },
})
