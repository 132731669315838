import Vue from 'vue'

import { defineStore } from 'pinia'
import { use_payments } from './payments_store'

export const use_tickets = defineStore('tickets', {
  state: () => ({
    all: [],
    loaded: false,
  }),

  actions: {
    set(tickets) {
      this.all = [...tickets]
      this.loaded = true
    },

    _index_of(ticket) {
      return this.all.findIndex(t => t.id === ticket.id)
    },

    async create(ticket) {
      const payments = use_payments()
      const { data } = await Vue.smcb_axios.post(`${payments.base_url}/pricings/`, { pricing: ticket })
      this.all.push(data)
    },

    async update(ticket) {
      const payments = use_payments()
      const { data } = await Vue.smcb_axios.patch(`${payments.base_url}/pricings/${ticket.id}`, { pricing: ticket })
      Vue.set(this.all, this._index_of(ticket), data)
    },

    async delete(ticket) {
      const payments = use_payments()
      await Vue.smcb_axios.delete(`${payments.base_url}/pricings/${ticket.id}`)
      this.all.splice(this._index_of(ticket), 1)
    },
  },
})
