import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'

export const use_heads_up = defineStore('heads_up', {
  state: () => ({
    context: null,
    service_name: null,
    enabled: null,
    employees: [],
    selected: [],
  }),

  actions: {
    async load(context, service_name = null) {
      this.context = context
      this.service_name = service_name
      const url = this.build_url('populate')
      const { data } = await Vue.smcb_axios.get(url)
      this.enabled = data.enabled
      this.employees = data.employees
      this.selected = data.selected
      // this.models = Object.fromEntries(data.models.map(m => [m.id, m]))
    },

    async toggle_enabled(enabled) {
      const url = this.build_url('enable')
      await Vue.smcb_axios.put(url, { enabled })
      this.enabled = enabled
    },

    async update_selected(employee_ids) {
      const url = this.build_url('select')
      const { data } = await Vue.smcb_axios.put(url, { selected: employee_ids })
      this.selected = data.selected
    },

    build_url(action) {
      const s = this.service_name ? '/' + this.service_name : ''
      return `${use_smcb_gym().base_url}/heads-up-subs/${this.context}/${action}${s}`
    },
  },
})
