import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'
import Vue from 'vue'

export const use_smcb_gym_tags = defineStore('smcb_gym_tags', {
  state: () => ({
    available: [],
    linked: [],
  }),

  actions: {
    async fetch() {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/location_tags`
      const { data } = await Vue.smcb_axios.get(url)
      this.available = data.available
      this.linked = data.linked
    },

    async link({ tag_id }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/location_tags`
      const { data } = await Vue.smcb_axios.post(url, { tag_id })
      this.available = data.available
      this.linked = data.linked
    },

    async unlink({ tag_id }) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/location_tags/${tag_id}`
      const { data } = await Vue.smcb_axios.delete(url)
      this.available = data.available
      this.linked = data.linked
    },
  },
})
