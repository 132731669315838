import { defineStore } from 'pinia'
import Vue from 'vue'

import { parse_participant } from './reservations_store'
import { use_reservations } from './reservations_store'

function clone_participant(p) {
  return parse_participant(JSON.parse(JSON.stringify(p)))
}

export const use_participant_edit = defineStore('participant_edit', {
  state: () => ({
    is_editing: false,
    is_saving: false,
    ps: null,
    extra_infos: {},
    save_the_booking_f: null,
  }),

  actions: {
    update_one(p) {
      this.ps = [clone_participant(p)]
      this.extra_infos = this.ps['0'].extra_infos
      this.is_editing = true
      this.is_saving = false
    },

    async save_the_booking_try() {
      if (this.save_the_booking_f) {
        await this.save_the_booking_f()
      }
    },

    async delete_uploaded_file({ participant_id, file_id }) {
      await Vue.smcb_axios.post(`${use_reservations().participant_url(participant_id)}/delete-file`, { file_id })
      this.extra_infos.uploaded_files = this.uploaded_files.filter(uf => uf.id !== file_id)
    },

    async update_uploaded_file({ participant_id, file }) {
      const { data } = await Vue.smcb_axios.post(`${use_reservations().participant_url(participant_id)}/update-file`, { file })
      const idx = this.extra_infos.uploaded_files.findIndex(uf => uf.id === file.id)
      Vue.set(this.extra_infos.uploaded_files, idx, data)
    },
  },

  getters: {
    first: state => state.ps && state.ps['0'],
    participant: state => edit_id => {
      return state.ps[parseInt(edit_id)]
    },

    uploaded_files: state => state.extra_infos?.uploaded_files,
  },
})
