import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_smcb_gym } from './smcb_gym_store'
import { use_task_bookings } from './task_bookings_store'

export const use_calendar_customers = defineStore('calendar_customers', {
  state: () => ({
    is_loading: false,
    all: [],
  }),

  actions: {
    async load() {
      this.is_loading = true
      try {
        const { data } = await Vue.smcb_axios.get(this.base_url)
        this.all = Object.freeze(data)
        return data
      } catch (e) {
        throw e
      } finally {
        this.is_loading = false
      }
    },

    async fetch_customer_participants(customer) {
      const response = await Vue.smcb_axios.get(`${this.base_url}/${customer.id}/participants`)

      if (response.data && response.status === 200) {
        return response.data
      } else {
        return false
      }
    },

    async update_customer_participant({ participant, customer }) {
      const url = `${this.base_url}/${customer.id}/participants/${participant.id}`
      const { data } = await Vue.smcb_axios.patch(url, participant)
      use_task_bookings().update_booking_customer_participant(data)
    },

    async delete_customer_participant({ participant, customer }) {
      const url = `${this.base_url}/${customer.id}/participants/${participant.id}`
      const { data } = await Vue.smcb_axios.delete(url, participant)
      use_task_bookings().delete_booking_customer_participant(data)
    },
  },

  getters: {
    base_url: () => {
      return use_smcb_gym().base_url + '/customers'
    },
  },
})
