import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

import Vue from 'vue'

export const use_prescriptions = defineStore('prescriptions', {
  state: () => ({
    orders: null,
    state_filter: 'new',
    counts: { new: 0, in_progress: 0, done: 0 },
    loading: true,
    search_results: null,
    settings: null,

    // Selected order (via token)
    selected_token: null,
    selected_order: null,
  }),

  actions: {
    async fetch_selected_order() {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/public/${this.selected_token}`)
      this.selected_order = data.order
    },

    async set_prescription_state_filter(state_filter) {
      this.loading = true
      this.state_filter = state_filter
      return await this.fetch_prescription_orders()
    },

    async fetch_prescriptions_settings() {
      const response = await Vue.smcb_axios.get(`${this.base_url}/settings`)
      if (response.status == 200) this.settings = response.data
      return response
    },

    async fetch_prescription_orders() {
      const response = await Vue.smcb_axios.get(`${use_smcb_gym().base_url}/prescriptions?state=${this.state_filter}`)
      if (response.status == 200 && response.data && response.data.new && response.data.in_progress && response.data.done) {
        this.settings = response.data.settings
        this.counts = { new: response.data.new.count, in_progress: response.data.in_progress.count, done: response.data.done.count }
        if (this.state_filter === 'new') {
          this.orders = response.data.new.orders
        } else if (this.state_filter === 'in_progress') {
          this.orders = response.data.in_progress.orders
        } else if (this.state_filter === 'done') {
          this.orders = response.data.done.orders
        }
      }
      this.loading = false
      return response
    },

    async update_prescription_state({ prescription, state_filter }) {
      return await Vue.smcb_axios.patch(`${this.base_url}/orders/${prescription}/state/`, { state: state_filter })
    },

    async update_prescription_participant({ prescription, participant }) {
      return await Vue.smcb_axios.patch(`${this.base_url}/orders/${prescription}/participant/`, {
        participant: participant,
      })
    },

    async update_prescription({ prescription, order }) {
      return await Vue.smcb_axios.patch(`${this.base_url}/orders/${prescription}`, { order: order })
    },

    async set_prescriptions_settings(settings) {
      const response = await Vue.smcb_axios.patch(`${this.base_url}/settings`, { settings: settings })
      if (response.status == 200) this.settings = response.data
      return response
    },

    async get_prescription_pdf(order_id) {
      return await Vue.smcb_axios.get(`${this.base_url}/orders/${order_id}/pdf`)
    },

    async delete_prescription_upload(upload_id) {
      return await Vue.smcb_axios.delete(`${this.base_url}/uploads/${upload_id}`)
    },

    async search_prescriptions(search_string) {
      this.search_results = null
      const response = await Vue.smcb_axios.get(`${this.base_url}/search/${search_string}`)
      if (response.status == 200 && response.data) this.search_results = response.data
      return response
    },

    async export_prescriptions(filetype) {
      const response = await Vue.smcb_axios.get(`${this.base_url}/export/${filetype}`)
      if (response.status == 200 && response.data) return response.data.url
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/prescriptions`,
  },
})
