import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'
import { use_task_groups } from './task_groups_store'

export const use_work_categories = defineStore('work_categories', {
  state: () => ({
    all: [],
    employees: {},
  }),

  actions: {
    async load_all_work_category_employees() {
      const url = `${use_smcb_gym().base_url}/work_category_employees`
      const { data } = await Vue.smcb_axios.get(url)

      for (let [work_category_id, employee_ids] of Object.entries(data)) {
        Vue.set(this.employees, work_category_id, employee_ids)
      }
    },

    async create(work_category) {
      const { data } = await Vue.smcb_axios.post(this.base_url, work_category)
      this.all = [...this.all, data]
      return data
    },

    async update(work_category) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url}/${work_category.id}`, work_category)

      this.all = this.all.map(t => {
        if (t.id !== data.id) {
          return t
        } else {
          return data
        }
      })
    },

    async delete(work_category) {
      await Vue.smcb_axios.delete(`${this.base_url}/${work_category.id}`)
      this.all = this.all.filter(t => t.id !== work_category.id)
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/workcategories`,

    all_work_categories: state => {
      const locale = I18n.locale || 'en'
      return [...state.all].sort((a, b) => a.name.localeCompare(b.name, locale, { sensitivity: 'base' }))
    },

    active() {
      return this.all_work_categories.filter(category => !category.archived_at)
    },

    from_business_unit_id: () =>
      function (id) {
        return this.active.filter(t => t.business_unit_id === id)
      },

    context_courses: () =>
      function (business_unit_id) {
        return this.ids_of_matching_course_or_empty(business_unit_id, true)
      },

    context_tasks: () =>
      function (business_unit_id) {
        return this.ids_of_matching_course_or_empty(business_unit_id, false)
      },

    ids_of_matching_course_or_empty: () =>
      function (id, is_course) {
        const use = {
          task_groups: use_task_groups(),
        }
        const work_categories = this.from_business_unit_id(id)
        const r = work_categories
          .filter(wc => {
            const { task_groups, is_empty } = use.task_groups.matching_course(wc.id, is_course)
            return (is_empty && wc.default_course === is_course) || task_groups.length > 0
          })
          .map(wc => Number(wc.id))
        return r
      },

    from_id: state => id => state.all.find(t => t.id === id),

    is_qualified_employee_for_work_category: state =>
      function (work_category_id, employee_id) {
        const wc_employees = state.employees[work_category_id]
        if (wc_employees) return wc_employees.includes(employee_id)

        const wc = this.from_id(work_category_id)
        if (wc) return wc.employee_ids.includes(employee_id)

        return false
      },
  },
})
