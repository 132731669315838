import { DateTime, Interval } from 'luxon'
import { defineStore } from 'pinia'
import Big from 'big.js'
import Vue from 'vue'

import { use_smcb_gym } from './smcb_gym_store'
import { use_entry_point } from './entry_point_store'

function parse_employee(employee) {
  return Object.freeze(employee)
}

function parse_fixed_wage(fixed_wage) {
  return {
    ...fixed_wage,
    monthly_wage: new Big(fixed_wage.monthly_wage),
    active_from: Vue.$vl_time.parse_as_local(fixed_wage.active_from),
    active_until: Vue.$vl_time.parse_as_local(fixed_wage.active_until),
  }
}

export const use_calendar_employees = defineStore('calendar_employees', {
  state: () => ({
    all: [],
    loaded: false,
    fred_users: [],
    employee_fixed_wages: {},
    employee_time_modal: {},
  }),

  actions: {
    add(employee) {
      this.all.push(parse_employee(employee))
    },

    set_employee(employee) {
      const index = this.all.findIndex(e => e.id === employee.id)

      if (index === -1) {
        this.add(employee)
      } else {
        Vue.set(this.all, index, parse_employee(employee))
      }
    },

    update_employee_field({ employee_id, key, value }) {
      const employee = this.all.find(e => e.id === employee_id)
      const updated_employee = { ...employee, [key]: value }
      this.set_employee(updated_employee)
    },

    async update(employee) {
      const url = this.url_of_existing_employee(employee.id)
      const { data } = await Vue.smcb_axios.patch(url, employee)
      this.set_employee(data)
    },

    async fetch_calendar_share_url(force) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/ical_url?force=${force}`)
      return data.url
    },

    async upload_employee_avatar({ form_data, employee_id }) {
      const url = this.url_of_employee_avatar(employee_id)
      const { data } = await Vue.smcb_axios.post(url, form_data)
      if (data) this.add_avatar({ employee_id, image: data })
      return data
    },

    add_avatar({ employee_id, image }) {
      console.assert(employee_id === image.imageable_id)
      const employee = this.all.find(e => e.id === employee_id)
      if (employee) {
        const updated_employee = { ...employee }
        updated_employee.avatar_path = image.full_image_path
        updated_employee.avatar_thumbnail_path = image.full_thumbnail_path
        this.set_employee(updated_employee)
      }
    },

    async update_role_permissions({ employee, login_email }) {
      const url = `${this.url_of_existing_employee(employee.id)}/update_role_permissions`
      const { data } = await Vue.smcb_axios.patch(url, { role: employee.role, permissions: employee.permissions, login_email })
      this.set_employee(data)
    },

    async create_ak_account(employee_id, login_email) {
      const url = `${this.url_of_existing_employee(employee_id)}/ak_account`
      const { data } = await Vue.smcb_axios.put(url, { login_email: login_email })
      this.set_employee(data)
    },

    async delete_ak_account(employee_id) {
      const url = `${this.url_of_existing_employee(employee_id)}/ak_account`
      const { data } = await Vue.smcb_axios.delete(url)
      this.set_employee(data)
    },

    async fetch_employees() {
      const response = await Vue.smcb_axios.get(this.base_url)
      if (response.data && response.status === 200) {
        this.set_employees(response.data)
        this.loaded = true
      }
    },

    async fetch_employee(employee_id) {
      const response = await Vue.smcb_axios.get(this.url_of_existing_employee(employee_id))

      if (response.data && response.status === 200) {
        this.set_employee(response.data)
      }
    },

    update_employee_fixed_wages({ employee_id, fixed_wages }) {
      const employee_fixed_wages = (this.employee_fixed_wages[employee_id] || []).filter(fw => !fixed_wages.some(fw2 => fw.id === fw2.id))
      employee_fixed_wages.push(...fixed_wages.map(parse_fixed_wage))
      Vue.set(this.employee_fixed_wages, employee_id, employee_fixed_wages)
    },

    set_employee_time_modal_obj({ source, obj }) {
      this.employee_time_modal = { [source]: obj }
    },

    clear_employee_time_modal_obj() {
      this.employee_time_modal = {}
    },

    set_employees(employees) {
      this.all = employees.map(parse_employee)
    },
  },

  getters: {
    base_url: () => {
      return `${use_smcb_gym().base_url}/employees`
    },

    from_gym_user_id: state => id => {
      const find = id => state.all.find(e => e.vl_gym_user_id === id)
      if (state.all.length === 0) return null // employees are loading
      if (state.all.length === 1) return find(id) // only the current employee is loaded, others employees are loading

      const employee = find(id)
      console.assert(employee, `VL: Mismatch between UserGym and Employee entities: ${id}`)
      return employee
    },

    from_id: state => id => {
      // Not loaded, called by computed on component build
      if (!id || state.all.length === 0) return null

      // If loaded, then we assume employee ID should exist
      const employee = state.all.find(e => e.id === id)
      console.assert(employee, `VL: Employee ID not found: ${id}`)
      return employee
    },

    gym_user_from_id: state => id => {
      return state.fred_users.find(u => u.id === id)
    },

    filterable_employees: state => {
      const valid_domain = Object.fromEntries(state.fred_users.map(u => [u.id, true]))
      const can_login = Object.fromEntries(state.fred_users.map(u => [u.id, u.status === 'active']))
      return state.all.filter(e => can_login[e.vl_gym_user_id] && valid_domain[e.vl_gym_user_id])
    },

    sorted_filterable_employees() {
      return [...this.filterable_employees].sort((a, b) => Vue.$vl_utils.compare_employees_by_fullname(a, b))
    },

    inactive_employees: state => {
      const valid_domain = Object.fromEntries(state.fred_users.map(u => [u.id, true]))
      const inactive = Object.fromEntries(state.fred_users.map(u => [u.id, u.status === 'disabled']))
      return state.all.filter(e => inactive[e.vl_gym_user_id] && valid_domain[e.vl_gym_user_id])
    },

    url_of_existing_employee: () =>
      function (employee_id) {
        return `${this.base_url}/${employee_id}`
      },

    url_of_employee_avatar: () =>
      function (employee_id) {
        return `${this.url_of_existing_employee(employee_id)}/avatar`
      },

    // added
    avatar_image_url: () => path => {
      return `${use_entry_point().cloudfront}${path}`
    },

    get_employee_fixed_wages_for_range:
      state =>
      (employee_id, range_fr, range_to = null) => {
        return (state.employee_fixed_wages[employee_id] || []).filter(fw => {
          if (Vue.$vl_utils.is_blank(fw.active_until)) return fw.active_from <= range_to
          return fw.active_from <= range_to && fw.active_until >= range_fr && +fw.active_from !== +fw.active_until
        })
      },

    get_employee_fixed_wages_sum_for_range: () =>
      function (employee_id, range_fr, range_to) {
        range_fr = range_fr.startOf('day')
        range_to = range_to.endOf('day')
        const months = Vue.$vl_time.get_months_in_interval(range_fr, range_to)
        const days_in_interval = (from, to) => Math.ceil(Interval.fromDateTimes(from, to).length('days'))

        return this.get_employee_fixed_wages_for_range(employee_id, range_fr, range_to)
          .reduce((total_sum, fw) => {
            const fixed_cost_cost = months.reduce((months_sum, [month, year]) => {
              // get metadata from the month being computed
              const days_in_month = DateTime.fromObject({ year, month }).daysInMonth
              const month_start_date = DateTime.fromObject({ year, month }).startOf('day')
              const month_end_date = DateTime.fromObject({ year, month, day: days_in_month }).endOf('day')

              // get the start/end date to use for the cost calculation to match what is asked and what the fixed wages are
              const active_from_in_month = DateTime.max(fw.active_from, month_start_date, range_fr)
              const active_until_in_month = fw.active_until ? DateTime.min(fw.active_until, month_end_date, range_to) : DateTime.min(month_end_date, range_to)

              // calculate the cost and sum it
              if (active_from_in_month > active_until_in_month) return months_sum
              const fixed_wage_days_in_month = days_in_interval(active_from_in_month, active_until_in_month)
              const quotient = new Big(fixed_wage_days_in_month).div(days_in_month)
              return months_sum.plus(quotient.times(fw.monthly_wage))
            }, new Big(0))

            return fixed_cost_cost.plus(total_sum)
          }, new Big(0))
          .round(2)
      },

    is_employee_loaded: state => id => state.all.some(e => e.id === id),
  },
})
