import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_tasks } from './tasks_store'
import { use_smcb_gym } from './smcb_gym_store'

function parse_booking(booking) {
  return Object.freeze({ ...booking, created_at: Vue.$vl_time.parse_as_local(booking.created_at) })
}

export const use_task_bookings = defineStore('task_bookings_store', {
  state: () => ({
    bookings: [],
    booking_for_receipt: null,
    show_receipt_modal: false,
    participant_to_cancel: null,
    show_cancel_participant_modal: false,
    bookings_requests: [],
    loaded_bookings_urls: [],
    canceled_booking_states: ['canceled', 'expired', 'refunded'],

    // Booking customer
    customer: null,
    customer_participants: [],
  }),

  actions: {
    async load({ start, end }) {
      const urls = new Set()

      for (let i = 0; start.plus({ months: i }).startOf('month') < end; i++) {
        let month = start.plus({ months: i })
        let url = `${use_smcb_gym().base_url}/bookings/${month.year}/${month.month}`
        if (!this.loaded_bookings_urls.includes(url)) {
          this.loaded_bookings_urls.push(url)
          urls.add(url)
        }
      }

      if (urls.size > 0) {
        await this.cancel_running_bookings_requests()
        await this.load_from_urls(urls)
        return this.bookings
      } else {
        return Promise.resolve(this.bookings)
      }
    },

    async load_from_urls(urls) {
      const self = this
      await Promise.all(
        [...urls].map(url => {
          Vue.smcb_axios
            .get(url, {
              before(request) {
                self.bookings_requests.push(request)
              },
            })
            .then(response => {
              self.add_bookings(response.data)

              self.bookings_requests.splice(
                self.bookings_requests.findIndex(r => r.url === response.url),
                1
              )
            })
            .catch(error => {
              if (error.status !== 0) throw error
            })
        })
      )
    },

    async reload_bookings_for_task(task_id) {
      const url = `${use_tasks().task_api}/${task_id}/bookings`
      const { data } = await Vue.smcb_axios.get(url)
      this.add_bookings(data)
    },

    async cancel_running_bookings_requests() {
      this.bookings_requests.forEach(request => request.abort())
      this.bookings_requests = []
    },

    async cancel_participant(participant) {
      const response = await Vue.smcb_axios.post(`${use_smcb_gym().base_url}/bookings/${participant.booking_id}/cancel_participant/${participant.id}`)
      if (response.status !== 200) throw new Error('Error cancelling participant')
      this.update_calendar_booking(response.data)
      return response.data
    },

    update_calendar_booking(booking) {
      this.bookings = this.bookings.filter(b => b.id !== booking.id)
      this.bookings.push(parse_booking(booking))
    },

    add_bookings(bookings) {
      const old_bookings = this.bookings
      const new_bookings = bookings.filter(t => !old_bookings.some(u => u.id === t.id)).map(parse_booking)
      this.bookings.push(...new_bookings)
    },

    open_booking_receipt_modal(booking) {
      this.booking_for_receipt = booking
      this.show_receipt_modal = true
    },

    update_receipt_modal_booking(booking) {
      this.booking_for_receipt = booking
    },

    open_cancel_participant_modal(participant) {
      this.participant_to_cancel = participant
      this.show_cancel_participant_modal = true
    },

    close_cancel_participant_modal() {
      this.participant_to_cancel = null
      this.show_cancel_participant_modal = false
    },

    close_booking_for_receipt() {
      this.booking_for_receipt = null
      this.show_receipt_modal = false
    },

    reset_booking_customer_data() {
      this.customer = null
      this.customer_participants = []
    },

    async save_task_manual_booking(payload) {
      const url = `${use_smcb_gym().base_url}/bookings/tasks/${payload.task_id}`

      try {
        const response = await Vue.smcb_axios.post(url, payload)
        return response.data
      } catch (e) {
        if (e.response.status === 409) {
          throw new Error('schedule_config_changed')
        } else if (e.response.status === 410) {
          throw new Error('overbooking')
        } else if (e.response.status === 413) {
          throw new Error('file_too_large')
        }

        throw new Error(e.message)
      }
    },

    update_booking_customer_participant(cp) {
      Vue.set(
        this.customer_participants,
        this.customer_participants.findIndex(x => x.id === cp.id),
        cp
      )
    },

    async update_customer_participant({ participant, customer }) {
      const url = `${use_smcb_gym().base_url}/customers/${customer.id}/participants/${participant.id}`
      const { data } = await Vue.smcb_axios.patch(url, participant)
      this.update_booking_customer_participant(data)
    },

    delete_booking_customer_participant(id) {
      Vue.delete(
        this.customer_participants,
        this.customer_participants.findIndex(cp => cp.id === id)
      )
    },

    async delete_customer_participant({ participant, customer }) {
      const url = `${use_smcb_gym().base_url}/customers/${customer.id}/participants/${participant.id}`
      await Vue.smcb_axios.delete(url, participant)
      this.delete_booking_customer_participant(participant.id)
    },
  },

  getters: {
    bookings_for_task: state => task_id => state.bookings.filter(b => b.bookable_id === task_id && b.bookable_type === 'Task'),

    bookings_for_recurring_task: state => recurring_task_id =>
      state.bookings.filter(b => b.bookable_id === recurring_task_id && b.bookable_type === 'RecurringTask'),
  },
})
