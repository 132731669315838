import { defineStore } from 'pinia'
import { io } from 'socket.io-client'

export const use_companion_app = defineStore('companion_app', {
  state: () => ({
    connected: false,
    connection: null,
    retry_delay_s: 4,
    retry_timeout: null,
    socket: null,
    url: null,
    user: {},
  }),

  actions: {
    stop() {
      this.connection?.disconnect()
      this.socket?.socket?.disconnect() // Probably not necessary
      this.socket = null
    },

    init({ url }) {
      this.url = url

      if (this.socket) return
      if (this.connected) return

      console.info(`VL Attempting connect to No-Q Companion App at "${this.url}"`)

      // Abort any scheduled reconnection attempt
      if (this.retry_timeout) {
        clearTimeout(this.retry_timeout)
        this.retry_timeout = null
      }

      // Setup connection
      this.connection = io.connect(this.url, {
        auth: {
          token: window.the_vl_jwt || document.head.querySelector('meta[name="user-token"]').content,
        },
        reconnection: false, // Disable library's auto-reconnect
      })

      // The app replied successfully
      this.connection.on('connect', socket => {
        console.info('VL Connected to companion app!')
        this.socket = socket
        this.connected = true
      })

      // The app send us the user data
      this.connection.on('user', user => {
        this.user = user
      })

      // Method to schedule a reconnection attempt
      const schedule_reconnect = should_reset => {
        // Reset data
        this.connected = false
        this.socket = null

        // Manage the delay
        if (should_reset) {
          this.retry_delay_s = 5
        } else {
          this.retry_delay_s = Math.min(this.retry_delay_s + 1, 10)
        }

        // Schedule callback
        this.retry_timeout = setTimeout(() => {
          if (this.url === url) this.init({ url })
        }, this.retry_delay_s * 1000)
      }

      this.connection.on('disconnect', () => {
        console.warn('VL Disconnected from companion app! Trying to reconnect ...')
        schedule_reconnect(true)
      })

      this.connection.on('connect_error', error => {
        console.warn(`VL Is No-Q Companion App running? Retrying in ${this.retry_delay_s + 1}s`)
        schedule_reconnect(false)
      })

      this.connection.on('error', error => {
        console.error('VL Companion App connection error', error)
      })
    },
  },
})
