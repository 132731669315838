import Big from 'big.js'
import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'
import { use_tasks } from '@core/entry_point/stores/tasks_store'
import { use_working_hours } from './working_hours_store'

function equals(a, b) {
  if (!a || !b) return false
  const date_equals = Vue.$vl_time.parse_as_local(a.date) === Vue.$vl_time.parse_as_local(b.date)
  const name_equals = a.name === b.name
  const regions_equals = a.regions.length === b.regions.length && a.regions.every(r => b.regions.includes(r))
  return date_equals && name_equals && regions_equals
}

function parse_public_holiday(public_holiday) {
  return Object.freeze({
    ...public_holiday,
    date: Vue.$vl_time.parse_as_local(public_holiday.date),
  })
}

export const use_public_holidays = defineStore('public_holidays', {
  state: () => ({
    all: [],
    loaded_public_holidays_urls: [],
    public_holidays_requests: [],
  }),

  actions: {
    add_public_holidays(public_holidays) {
      const old_public_holidays = this.all
      const new_public_holidays = public_holidays.filter(t => !old_public_holidays.some(u => equals(u, t))).map(parse_public_holiday)
      this.all.push(...new_public_holidays)
    },

    remove_public_holidays_request(url) {
      this.public_holidays_requests.splice(
        this.public_holidays_requests.findIndex(r => r.url === url),
        1
      )
    },

    async load({ start, end }) {
      let urls = new Set()

      for (let i = 0; start.plus({ months: i }).startOf('month') < end; i++) {
        let month = start.plus({ months: i })
        let url = `${this.base_url}/${month.year}/${month.month}`

        if (!this.loaded_public_holidays_urls.includes(url)) {
          this.loaded_public_holidays_urls.push(url)
          urls.add(url)
        }
      }

      return Promise.all(
        [...urls].map(url => {
          return Vue.smcb_axios
            .get(url, {
              before(request) {
                // add the request to store so it can be canceled if necessary
                this.public_holidays_requests.push(request)
              },
            })
            .then(response => {
              this.add_public_holidays(response.data || [])
              // if it returned, remove the request
              this.remove_public_holidays_request(response.url)
            })
            .catch(error => {
              // if the request is a canceled one, the status will be 0 so no error needs to be thrown
              if (error.status !== 0) throw error
            })
        })
      )
    },

    async fetch_employees_and_wage_type_for_date(date) {
      const url = `${this.compensation_url}/${date.get('year')}/${date.get('month')}/${date.get('day')}`
      const { data } = await Vue.smcb_axios.get(url)

      return {
        compensations: data.public_holiday_compensations.map(c => ({
          ...c,
          hours: new Big(c.hours),
          hourly_compensation: c.hourly_compensation == null ? null : new Big(c.hourly_compensation),
        })),
        employees: data.employees.reduce((result, obj) => ((result[obj.id] = obj.has_fixed_wage), result), {}),
      }
    },

    async save_public_holiday_compensations({ date, payload }) {
      const { data } = await Vue.smcb_axios.post(`${this.compensation_url}`, { date, payload })

      if (data.archived_events) {
        const working_hours = use_working_hours()

        for (const [employee_id, events] of Object.entries(data.archived_events)) {
          events.task_ids.forEach(task_id => use_tasks().unassign_employee({ task_id, employee_id: parseInt(employee_id) }))
          events.working_hours.forEach(wh => working_hours.restrict_working_hours_log(wh))
          events.working_hours_logs.forEach(whl => working_hours.add_or_update_working_hours_log(whl))
        }
      }
    },

    async check_compensations_overlap({ date, employee_ids }) {
      const { data } = await Vue.smcb_axios.post(`${this.compensation_url}/check_overlap`, { date, employee_ids })
      return data.overlap
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/public_holidays`,
    compensation_url: () => `${use_smcb_gym().base_url}/public_holiday_compensations`,
  },
})
