import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_pay_levels } from './pay_levels_store'
import { use_smcb_gym } from './smcb_gym_store'

const date_format_str = 'yyyy-MM-dd'

function parse_employment(employment) {
  return Object.freeze({
    ...employment,
    last_audit: employment.last_audit ? parse_audit(employment.last_audit) : null,
    start_date: Vue.$vl_time.parse_as_local(employment.start_date),
    end_date: employment.end_date ? Vue.$vl_time.parse_as_local(employment.end_date) : null,
  })
}

function parse_audit(audit) {
  return Object.freeze({
    ...audit,
    created_at: Vue.$vl_time.parse_as_local(audit.created_at),
  })
}

export const use_employments = defineStore('employments', {
  state: () => ({
    employments_per_employee: {},
    employments_with_vacations_per_employee: {},
    employments_loaded_per_employee: {},
    employments_with_vacations_loaded_per_employee: {},
  }),

  actions: {
    async fetch(employee) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/employee/${employee.id}`)
      Vue.set(this.employments_per_employee, employee.id, data.map(parse_employment))
      Vue.set(this.employments_loaded_per_employee, employee.id, true)
    },

    async fetch_with_vacations(employee) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/employee/${employee.id}/vacations`)
      Vue.set(this.employments_with_vacations_per_employee, employee.id, data.map(parse_employment))
      Vue.set(this.employments_with_vacations_loaded_per_employee, employee.id, true)
    },

    async fetch_in_range({ start_date, end_date, employee_ids }) {
      const { data } = await Vue.smcb_axios.get(this.base_url, {
        params: {
          employee_ids: employee_ids.join(','),
          start_date: start_date.toFormat(date_format_str),
          end_date: end_date.toFormat(date_format_str),
        },
      })

      return Vue.$vl_utils.transform_values(data, employments => employments.map(parse_employment))
    },

    async fetch_audits({ employee_id, page, page_size, sort_by, sort_desc }) {
      const response = await Vue.smcb_axios.post(`${this.base_url}/employee/${employee_id}/audit`, { page, page_size, sort_by, sort_desc })

      return {
        page,
        audits: response.data.map(parse_audit),
        count: Number(response.headers.get('Employments-Audits-Count')),
        page_size: Number(response.headers.get('Employments-Page-Size')),
      }
    },

    async create({ employee, employment }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/employee/${employee.id}`, employment)
      Vue.set(this.employments_per_employee, employee.id, this.employments_per_employee[employee.id] || [])
      this.employments_per_employee[employee.id].push(parse_employment(data))
      return data.id
    },

    async update_yearly_vacation_days({ employee, employment, year, vacation_days }) {
      const { data } = await Vue.smcb_axios.put(`${this.base_url}/${employment.id}/yearly_vacation_days`, { year, vacation_days })
      const index = this.employments_with_vacations_per_employee[employee.id].findIndex(e => e.id === employment.id)
      Vue.set(this.employments_with_vacations_per_employee[employee.id], index, parse_employment(data))
    },

    async delete({ employee, employment }) {
      await Vue.smcb_axios.delete(`${this.base_url}/${employment.id}`)
      const index = this.employments_per_employee[employee.id].findIndex(e => e.id === employment.id)
      Vue.delete(this.employments_per_employee[employee.id], index)
    },

    async terminate({ employee, employment, payload }) {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url}/${employment.id}/end`, payload)
      const index = this.employments_per_employee[employee.id].findIndex(e => e.id === employment.id)
      Vue.set(this.employments_per_employee[employee.id], index, parse_employment(data))
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/employments`,
    get_employments_by_employee: state => employee => state.employments_per_employee[employee.id] || [],
    get_employments_with_vacations_by_employee: state => employee => state.employments_with_vacations_per_employee[employee.id] || [],

    get_employee_prepared_employments: () =>
      function (employee) {
        const today = Vue.$vl_time.get_today()
        const employee_employments = this.get_employments_by_employee(employee)

        const current_employment_index = employee_employments.findIndex(e => {
          // exclude if it starts after today
          if (e.start_date.startOf('day') > today) return false
          // exclude if it already ended
          if (e.end_date && e.end_date.startOf('day') < today) return false
          // we found the current employment
          return true
        })

        // no current employment
        if (current_employment_index === -1) {
          return [null, employee_employments]
        }

        const current_employment = employee_employments[current_employment_index]
        const other_employments = [...employee_employments]
        other_employments.splice(current_employment_index, 1)
        return [current_employment, other_employments]
      },

    get_employee_current_employment: () =>
      function (employee) {
        return this.get_employee_prepared_employments(employee)[0]
      },

    get_employee_other_employments: () =>
      function (employee) {
        return [...this.get_employee_prepared_employments(employee)[1]].sort((a, b) => b.start_date - a.start_date)
      },

    employee_has_employment: () =>
      function (employee) {
        return !!this.get_employee_current_employment(employee)
      },

    employee_has_fixed_wage_without_employment: () =>
      function (employee) {
        return use_pay_levels().employee_has_fixed_wage(employee) && !this.employee_has_employment(employee)
      },

    employee_has_flex_wage_without_employment: () =>
      function (employee) {
        return use_pay_levels().employee_has_flex_wage(employee) && !this.employee_has_employment(employee)
      },

    employee_has_fixed_wage_with_flex_employment: () =>
      function (employee) {
        return (
          use_pay_levels().employee_has_fixed_wage(employee) &&
          this.employee_has_employment(employee) &&
          this.get_employee_current_employment(employee).parent_type === 'flex'
        )
      },

    employee_has_flex_wage_with_fixed_employment: () =>
      function (employee) {
        return (
          use_pay_levels().employee_has_flex_wage(employee) &&
          this.employee_has_employment(employee) &&
          this.get_employee_current_employment(employee).parent_type === 'fixed'
        )
      },
  },
})
