import Vue from 'vue'
import axios from 'axios'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'
import { use_demis } from './demis_store'

function parse_participant_summary(ps) {
  ps.checked_in_at = Vue.$vl_time.parse_as_local(ps.checked_in_at)
  ps.check_in_at = Vue.$vl_time.parse_as_local(ps.check_in_at)
  return ps
}

function data_uri_to_blob(data_uri) {
  var binary = window.atob(data_uri.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
}

export const use_quick_scanner = defineStore('quick_scanner', {
  state: () => ({
    last_qr_code_scanned: null,
    participant: null,
    results: null,
    feedback: {
      code: null,
      status: null,
      participant_id: null,
    },
  }),

  actions: {
    clear_feedback() {
      this.feedback.code = null
      this.feedback.message = null
      this.feedback.participant_id = null
    },

    set_feedback(code, message, participant_id) {
      this.feedback.code = code
      this.feedback.message = message || code
      this.feedback.participant_id = participant_id || null
    },

    clear_participant() {
      this.participant = null
      this.last_qr_code_scanned = null
    },

    async fetch_participant_for_result_judge({ participant_id, prefix }) {
      const demis = use_demis()

      const data = await this.fetch_participant({ participant_id, prefix })
      if (!data || !data.participant) return

      // Check if result already inserted
      const p = data.participant
      if (p && p.extra_fields && p.extra_fields.result) {
        this.set_feedback('error', 'preexisting')
        return
      }

      if (p && data.data_incomplete) {
        demis.clear()
        this.set_feedback('error', 'data_incomplete', p.id)
        return
      }

      this.results = data.results.sort()
      this.participant = parse_participant_summary(p)

      if (demis.running) {
        demis.clear()
        demis.set_active_participant_id(p.id)
      }
    },

    async fetch_participant_for_checkin({ participant_id, prefix }) {
      const data = await this.fetch_participant({ participant_id, prefix })
      if (!data || !data.participant) return

      const p = data.participant
      if (p && p.status === 'checked_out') {
        this.set_feedback('warning', 'status.warning.checked_out')
        return
      }
      this.participant = parse_participant_summary(p)
    },

    async fetch_participant({ participant_id, prefix }) {
      const smcb_gym = use_smcb_gym()

      const url = `${smcb_gym.base_url}/checkins/participants/for-result/${participant_id}/and/${prefix}`
      try {
        const { data } = await Vue.smcb_axios.get(url)
        return data
      } catch (e) {
        if (e.response.status === 404) this.set_feedback('error', 'code_not_found')
        else this.set_feedback('error', 'error')
      }
      return null
    },

    async judge_saved_participant_result({ token, result, photo_included, employee, demis_test_type }) {
      const demis = use_demis()
      const smcb_gym = use_smcb_gym()

      const url = `${smcb_gym.base_url}/checkins/participants/for-result/${token}`
      try {
        await Vue.smcb_axios.patch(url, { result, photo_included, employee, demis_test_type })
        this.set_feedback('success', 'success')
      } catch (e) {
        this.set_feedback('error', 'error')
      }

      this.clear_participant()
    },

    async update_participant_status({ token, status }) {
      const demis = use_demis()
      const smcb_gym = use_smcb_gym()

      const url = `${smcb_gym.base_url}/checkins/participants/for-status/${token}`
      try {
        await Vue.smcb_axios.patch(url, { status })
        this.set_feedback('success', `status.success.${status}`)
        this.clear_participant()
      } catch (e) {
        this.set_feedback('error', `status.error.${status}`)
      }
    },

    async upload_result_proof({ token, photo }) {
      try {
        let photo_blob = data_uri_to_blob(photo)
        let photo_file = new File([photo_blob], 'result_proof.jpg', { type: 'image/jpeg' })

        const smcb_gym = use_smcb_gym()
        const url = `${smcb_gym.base_url}/checkins/participants/upload_url/${token}?context=is-result-proof`
        const { data } = await Vue.smcb_axios.get(url)
        await axios.put(data.upload_url, photo_file)
      } catch (e) {
        this.set_feedback('error', 'error')
        this.clear_participant()
        throw e
      }
    },
  },

  getters: {
    possible_results: state => [...(state.results || [])],
    feedback_if_present: state => (state.feedback.code ? { ...state.feedback } : null),
  },
})
