import { defineStore } from 'pinia'
import Big from 'big.js'
import Vue from 'vue'

import { use_calendar_employees } from '@core/entry_point/stores/calendar_employees_store'
import { use_smcb_gym } from './smcb_gym_store'
import { use_task_groups } from '@core/entry_point/stores/task_groups_store'
import { use_work_categories } from '@core/entry_point/stores/work_categories_store'

function parse_pay_level(pay_level) {
  return Object.freeze({
    ...pay_level,
    hourly_wage: new Big(pay_level.hourly_wage),
  })
}

function parse_fixed_wage_distribution(fixed_wage_distribution) {
  return {
    ...fixed_wage_distribution,
    distribution: new Big(fixed_wage_distribution.distribution),
  }
}

function compare_pay_levels(a, b) {
  return a.level_index - b.level_index
}

function get_pay_levels_map(work_categories, pay_levels) {
  const get_pay_levels_for_task_group = tg_id => {
    return pay_levels.filter(pl => pl.parent_type === 'TaskGroup' && pl.parent_id === tg_id).sort(compare_pay_levels)
  }

  const use_tg = use_task_groups()
  const get_wc_pay_levels_per_task_group = wc_id => {
    return Object.fromEntries(use_tg.active_task_groups_from_work_category_id(wc_id).map(tg => [tg.id, get_pay_levels_for_task_group(tg.id)]))
  }

  return Object.fromEntries(work_categories.map(wc => [wc.id, get_wc_pay_levels_per_task_group(wc.id)]))
}

function parse_audit(audit) {
  return {
    ...audit,
    created_at: Vue.$vl_time.parse_as_local(audit.created_at),
    old_value: typeof audit.old_value === 'undefined' ? null : new Big(audit.old_value),
    new_value: typeof audit.new_value === 'undefined' ? null : new Big(audit.new_value),
  }
}

export const use_pay_levels = defineStore('pay_levels', {
  state: () => ({
    pay_levels: [],
    pay_levels_per_employee: {},
    fixed_wage_per_employee: {},
    fixed_wage_distributions_per_employee: [],
    pay_levels_loaded_per_employee: {},
    settings: {
      custom_wage_level_index: null,
      fixed_wage_index: 'fixed',
    },
  }),

  actions: {
    set_employee_fixed_wage({ employee, fixed_wage }) {
      if (fixed_wage) {
        fixed_wage.monthly_wage = new Big(fixed_wage.monthly_wage)
        Vue.set(this.fixed_wage_per_employee, employee.id, fixed_wage)
      } else {
        Vue.set(this.fixed_wage_per_employee, employee.id, null)
        Vue.set(this.fixed_wage_distributions_per_employee, employee.id, [])
      }
    },

    async load() {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}`)
      this.pay_levels = data.map(parse_pay_level)
    },

    async fetch_employee_pay_levels(employee) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/employee/${employee.id}`)
      const fixed_wage = data.fixed_wage

      Vue.set(this.pay_levels_per_employee, employee.id, data.pay_levels.map(parse_pay_level))
      this.set_employee_fixed_wage({ employee, fixed_wage })

      if (fixed_wage?.fixed_wage_distributions) {
        Vue.set(this.fixed_wage_distributions_per_employee, employee.id, fixed_wage.fixed_wage_distributions.map(parse_fixed_wage_distribution))
      }

      Vue.set(this.pay_levels_loaded_per_employee, employee.id, true)
    },

    async batch_update_pay_levels(payload) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}`, payload)
      this.pay_levels = data.map(parse_pay_level)
    },

    async update_employee_pay_levels({ employee, payload }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/employee/${employee.id}`, payload)
      const fixed_wage = data.fixed_wage

      Vue.set(this.pay_levels_per_employee, employee.id, data.pay_levels.map(parse_pay_level))
      this.set_employee_fixed_wage({ employee, fixed_wage })

      if (fixed_wage?.fixed_wage_distributions) {
        Vue.set(this.fixed_wage_distributions_per_employee, employee.id, fixed_wage.fixed_wage_distributions.map(parse_fixed_wage_distribution))
      }

      for (const [task_group_id, employee_ids] of Object.entries(data.task_group_employees)) {
        use_task_groups().write_task_group_employees({ task_group_id, employee_ids })
      }

      use_calendar_employees().update_employee_field({ employee_id: employee.id, key: 'pay_level_index', value: data.employee.pay_level_index })

      return data
    },

    async fetch_task_employees_affected_by_update(pay_level_ids) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/task_employees_affected_by_update`, { pay_level_ids })
      return data.task_employees_affected || false
    },

    async get_pay_levels_update_costs({ dates, pay_levels }) {
      return await Promise.all(dates.map(date => this.get_pay_levels_update_costs_from_date({ date, pay_levels })))
    },

    async get_pay_levels_update_costs_from_date({ date, pay_levels }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/calculate_update_cost_difference`, { update_start: date, pay_levels })
      return data.difference
    },

    get_employee_pay_levels_update_costs({ dates, employee, task_groups }) {
      return Promise.all(dates.map(date => this.get_employee_pay_levels_update_costs_from_date({ date, employee, task_groups })))
    },

    async get_employee_pay_levels_update_costs_from_date({ date, employee, task_groups }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/calculate_employee_update_cost_difference`, {
        update_start: date,
        employee,
        task_groups,
      })
      return data.difference
    },

    async get_task_group_pay_levels_affected({ employee_id }) {
      const url = `${this.base_url}/employee/task_group_pay_levels_affected`
      const { data } = await Vue.smcb_axios.post(url, { employee_id })
      return data.task_groups_affected_count
    },

    async fetch_task_group_pay_level_audits({ task_group_ids, page, page_size, filters, sort_by, sort_desc }) {
      const response = await Vue.smcb_axios.post(`${this.base_url}/audits`, { task_group_ids, page, page_size, filters, sort_by, sort_desc })

      return {
        page,
        audits: response.data.map(parse_audit),
        count: Number(response.headers.get('Pay-Levels-Audits-Count')),
        page_size: Number(response.headers.get('Pay-Levels-Audits-Page-Size')),
      }
    },

    async fetch_employee_pay_level_audits({ employee_id, page, page_size, filters, sort_by, sort_desc }) {
      const response = await Vue.smcb_axios.post(`${this.base_url}/audits/employee/${employee_id}`, { page, page_size, filters, sort_by, sort_desc })

      return {
        page,
        audits: response.data.map(parse_audit),
        count: Number(response.headers.get('Employee-Pay-Levels-Audits-Count')),
        page_size: Number(response.headers.get('Employee-Pay-Levels-Audits-Page-Size')),
      }
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/pay_levels`,
    employee_pay_levels: state => employee => state.pay_levels_per_employee[employee.id] || [],
    get_employee_fixed_wage: state => employee => state.fixed_wage_per_employee[employee.id],
    get_employee_fixed_wage_distributions: state => employee => state.fixed_wage_distributions_per_employee[employee.id],
    gym_pay_levels: state => state.pay_levels.filter(pl => pl.parent_type === 'Gym').sort(compare_pay_levels),
    pay_levels_map: state => get_pay_levels_map(use_work_categories().active, state.pay_levels),
    pay_levels_loaded_for_employee: state => employee_id => !!state.pay_levels_loaded_per_employee[employee_id],

    employee_custom_pay_level: () =>
      function (employee) {
        const custom_pay_levels = this.employee_pay_levels(employee).filter(pl => pl.parent_type === 'Employee')
        console.assert(custom_pay_levels.length < 2, 'VL: no more than one custom pay level is allowed for an employee')
        return custom_pay_levels[0]
      },

    employee_pay_levels_map: () =>
      function (employee) {
        return get_pay_levels_map(use_work_categories().active, this.employee_pay_levels(employee))
      },

    employee_has_fixed_wage: () =>
      function (employee) {
        return !!this.get_employee_fixed_wage(employee)
      },

    employee_has_flex_wage: () =>
      function (employee) {
        return !this.employee_has_fixed_wage(employee)
      },
  },
})
