import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_smcb_gym } from './smcb_gym_store'
import { use_tasks } from './tasks_store'
import { use_working_hours } from './working_hours_store'

const date_format_str = 'yyyy-MM-dd'

function parse_audit(audit) {
  return Object.freeze({
    ...audit,
    created_at: Vue.$vl_time.parse_as_local(audit.created_at),
  })
}

export const use_absences = defineStore('absences_store', {
  state: () => ({
    absences: [],
    loaded_absences_urls: [],
    absences_requests: [],
  }),

  actions: {
    add_absences(absences) {
      const old_absences = this.absences
      const new_absences = absences.filter(t => !old_absences.some(u => t.id === u.id)).map(a => Vue.$absences.parse(a))
      this.absences.push(...new_absences)
    },

    remove_absences_request(url) {
      this.absences_requests.splice(
        this.absences_requests.findIndex(r => r.url === url),
        1
      )
    },

    add_absence(absence) {
      this.absences = this.absences.filter(a => a.id !== absence.id)
      this.absences.push(Vue.$absences.parse(absence))
    },

    remove_absence(absence_id) {
      const idx = this.absences.findIndex(a => a.id === absence_id)
      if (idx !== -1) Vue.delete(this.absences, idx)
    },

    async load({ start, end }) {
      let urls = new Set()

      for (let i = 0; start.plus({ months: i }).startOf('month') < end; i++) {
        let month = start.plus({ months: i })
        let url = `${this.base_url}/${month.year}/${month.month}`

        if (!this.loaded_absences_urls.includes(url)) {
          this.loaded_absences_urls.push(url)
          urls.add(url)
        }
      }

      return Promise.all(
        [...urls].map(url => {
          return Vue.smcb_axios
            .get(url, {
              before(request) {
                // add the request to store so it can be canceled if necessary
                this.absences_requests.push(request)
              },
            })
            .then(response => {
              this.add_absences(response.data || [])
              this.remove_absences_request(response.url)
            })
            .catch(error => {
              // if the request is a canceled one, the status will be 0 so no error needs to be thrown
              if (error.status !== 0) throw error
            })
        })
      )
    },

    async create(absence) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/employees/${absence.employee_id}`, absence)
      const { absence: new_absence, archived_events } = data
      this.add_absence(new_absence)
      archived_events.task_ids.forEach(task_id => use_tasks().unassign_employee({ task_id, employee_id: absence.employee_id }))

      const working_hours = use_working_hours()

      archived_events.working_hours.forEach(wh => {
        working_hours.restrict_working_hours_log(wh)
      })

      archived_events.working_hours_logs.forEach(whl => {
        working_hours.add_or_update_working_hours_log(whl)
      })
    },

    async update(absence) {
      const response = await Vue.smcb_axios.put(`${this.base_url}/employees/${absence.employee_id}/${absence.id}`, absence)

      // when updating the backend creates a new absence and the previous one is archived for auditing reasons
      // we then need to remove the previous absence version
      if (response.data && response.status === 200) {
        const { absence: new_absence, archived_events } = response.data
        this.remove_absence(absence.id)
        this.add_absence(new_absence)

        const working_hours = use_working_hours()

        archived_events.working_hours.forEach(wh => {
          working_hours.restrict_working_hours_log(wh)
        })

        archived_events.working_hours_logs.forEach(whl => {
          working_hours.add_or_update_working_hours_log(whl)
        })
      }
    },

    async delete(absence) {
      await Vue.smcb_axios.delete(`${this.base_url}/employees/${absence.employee_id}/${absence.id}`)
      this.remove_absence(absence.id)
    },

    async simulate({ employee, absence, disabled_intervals }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/employees/${employee.id}/simulate`, {
        ...absence,
        start_date: absence.start_date.toFormat(date_format_str),
        end_date: absence.end_date.toFormat(date_format_str),
        intervals: absence.intervals.map(interval => interval.date.toFormat(date_format_str)),
        disabled_intervals: disabled_intervals.map(interval => interval.date.toFormat(date_format_str)),
      })

      for (const simulation of Object.values(data.simulation)) {
        simulation.intervals = simulation.intervals.map(itv => ({
          ...itv,
          date: DateTime.fromFormat(itv.date, date_format_str),
        }))
      }

      return data
    },

    async fetch_audits({ employee_id, page, page_size, sort_by, sort_desc }) {
      const response = await Vue.smcb_axios.post(`${this.base_url}/employees/${employee_id}/audits`, { page, page_size, sort_by, sort_desc })

      return {
        page,
        audits: response.data.map(parse_audit),
        count: Number(response.headers.get('Absence-Audits-Count')),
        page_size: Number(response.headers.get('Absence-Page-Size')),
      }
    },

    async sick_days(employee_id) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/employees/${employee_id}/sick_days`)
      return data
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/absences`,
    vacations: state => state.absences.filter(a => a.type === 'vacation'),
    sick_leaves: state => state.absences.filter(a => a.type === 'sick_leave'),
  },
})
