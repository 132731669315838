import { defineStore } from 'pinia'
import Vue from 'vue'

function get_read_popup_warning_list_from_storage() {
  try {
    const read = JSON.parse(localStorage.read_popup_warnings)
    return Array.isArray(read) ? read : []
  } catch (e) {
    return []
  }
}

function process_incoming_warning(warning) {
  const read = get_read_popup_warning_list_from_storage()
  warning.valid_fr = Vue.$vl_time.parse_as_local(warning.valid_fr)
  warning.valid_to = Vue.$vl_time.parse_as_local(warning.valid_to)
  warning.has_been_read = read.includes(warning.id)
  return warning
}

export const use_popup_warnings = defineStore('popup_warnings', {
  state: () => ({
    all: {},
  }),

  actions: {
    add(warning) {
      Vue.set(this.all, warning.id, process_incoming_warning(warning))
    },

    remove(warning_id) {
      Vue.delete(this.all, warning_id)
    },

    mark_all_as_read() {
      Object.values(this.all).forEach(warning => {
        Vue.set(warning, 'has_been_read', true)
        const read = get_read_popup_warning_list_from_storage()
        read.push(warning.id)
        localStorage.read_popup_warnings = JSON.stringify(read)
      })
    },
  },
})
