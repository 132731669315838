import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

export const use_capacity_overview = defineStore('capacity_overview', {
  state: () => ({
    current_day: null,
    loading: false,
    presences: {},
  }),

  actions: {
    async silently_fetch({ service_id }) {
      const smcb_gym = use_smcb_gym()
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${smcb_gym.base_url}/services/${service_id}/checkins/capacity-overview/${date_path}`
      const { data } = await Vue.smcb_axios.get(url)
      Vue.set(this.presences, service_id, data)
    },

    async fetch({ service_id, date }) {
      this.loading = true
      this.current_day = date
      await this.silently_fetch({ service_id })
      this.loading = false
    },

    async duplicate_slot_def({ service_id, slot_def_id, start_time, capacity_increase, date }) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.post(`${smcb_gym.base_url}/services/${service_id}/checkins/slot_defs/${slot_def_id}/duplicate`, {
        start_time,
        capacity_increase,
        date,
      })

      Vue.set(this.presences, service_id, data)
    },
  },

  getters: {
    presences_by_date: state => {
      const result = {}
      Object.entries(state.presences).forEach(([s_id, s_presences]) => {
        Object.entries(s_presences).forEach(([date, values]) => {
          if (!result[date]) result[date] = {}
          result[date][s_id] = values
        })
      })

      return result
    },
  },
})
