import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

export const use_map_locators = defineStore('map_locators', {
  state: () => ({
    impftermine_info: null,
  }),

  actions: {
    //
    // TESTBUCHEN PRICES
    //
    async fetch_testbuchen_prices() {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/map_locators/testbuchen/prices`)
      return data
    },

    async save_testbuchen_prices({ prices }) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.post(`${smcb_gym.base_url}/map_locators/testbuchen/prices`, { prices })
      return data
    },

    async upload_impftermine_logo({ form_data }) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.post(`${smcb_gym.base_url}/map_locators/impftermine/logo`, form_data)
      this.impftermine_info = data.info
    },

    //
    // IHRE APOTHEKEN
    //
    async fetch_ihre_apotheken_settings() {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/map_locators/ihre_apotheken/services`)
      return data
    },

    async save_ihre_apotheken_settings({ enabled }) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.post(`${smcb_gym.base_url}/map_locators/ihre_apotheken/services`, { enabled })
      return data
    },

    //
    // IMPFTERMINE ALTERNATIVE CONTACTS DATA AND LOGO URL
    //
    async fetch_impftermine_info() {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/map_locators/impftermine/info`)
      this.impftermine_info = data.info
    },

    async save_impftermine_info(info) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.post(`${smcb_gym.base_url}/map_locators/impftermine/info`, { info: info })
      this.impftermine_info = data.info
    },
  },
})
