import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'
import { use_services } from './services_store'

export const use_demis = defineStore('demis', {
  state: () => ({
    completed: null,
    active_participant_id: null,
    validation_error_code: null,
  }),

  actions: {
    clear() {
      this.completed = null
      this.active_participant_id = null
    },

    set_active_participant_id(participant_id) {
      this.active_participant_id = participant_id
    },

    set_completed({ participant_id, message }) {
      if (participant_id === this.active_participant_id) {
        this.completed = true
      }

      Vue.$vl_utils.show_success_alert(message)
    },

    set_error(message) {
      this.completed = false

      Vue.$vl_utils.show_error_alert(message)
    },

    set_validation_error_code(code) {
      this.validation_error_code = code
    },

    async validate(credentials) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/validate-demis`

      try {
        await Vue.smcb_axios.post(url, credentials)
        return true
      } catch (e) {
        this.set_validation_error_code(e.status)
        return false
      }
    },

    async update_participant({ participant }) {
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant.token}/update-demis`
      await Vue.smcb_axios.patch(url, { participant })
    },
  },

  getters: {
    running() {
      const smcb_gym = use_smcb_gym()
      const services = use_services()
      return smcb_gym.gym_options.demis_opt_in_enabled && services.of_selected.settings.uses_demis_api
    },
  },
})
