import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'

export const use_rental = defineStore('rental', {
  state: () => ({
    models: {},
  }),

  actions: {
    async load() {
      const url = `${use_smcb_gym().base_url}/rental/populate`
      const { data } = await Vue.smcb_axios.get(url)
      this.models = Object.fromEntries(data.models.map(m => [m.id, m]))
    },

    async create_model(model) {
      const url = `${use_smcb_gym().base_url}/rental/rental_models`
      const { data } = await Vue.smcb_axios.post(url, { model })
      Vue.set(this.models, data.model.id, data.model)
    },

    async update_model(model) {
      const url = `${use_smcb_gym().base_url}/rental/rental_models/${model.id}`
      const { data } = await Vue.smcb_axios.put(url, { model })
      Vue.set(this.models, data.model.id, data.model)
    },

    async delete_model(model_id) {
      const url = `${use_smcb_gym().base_url}/rental/rental_models/${model_id}`
      await Vue.smcb_axios.delete(url)
      Vue.delete(this.models, model_id)
    },

    async upload_model_photo(payload) {
      const url = `${use_smcb_gym().base_url}/rental/rental_models/upload_photo`
      try {
        const { data } = await Vue.smcb_axios.post(url, payload)
        return data
      } catch (e) {
        const error = { error: true, response: e.response, code: 'unknown' }
        if (e.response.status === 413) error.code = 'file_too_large'
        throw error
      }
    },
  },
})
