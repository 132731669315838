import Vue from 'vue'
import Big from 'big.js'

import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

function parse_employment(employment, index) {
  const overtime_audits = employment.overtime_audits.map(audit => parse_audit(audit, index + 1))
  const performed_hours = new Big(employment.performed_hours || 0)
  const expected_hours = new Big(employment.expected_hours || 0)
  const adjusted_minutes = overtime_audits.reduce((sum, audit) => sum.plus(audit.correction_time), new Big(0))

  return Object.freeze({
    ...employment,
    ui_id: index + 1,
    overtime: performed_hours.times(60).minus(adjusted_minutes),
    overtime: performed_hours.minus(expected_hours).times(60).plus(adjusted_minutes),
    overtime_audits,
    start_date: Vue.$vl_time.parse_as_local(employment.start_date),
    end_date: employment.end_date ? Vue.$vl_time.parse_as_local(employment.end_date) : null,
  })
}

function parse_audit(audit, employment_ui_id) {
  return Object.freeze({
    ...audit,
    employment_ui_id,
    correction_time: new Big(audit.correction_time),
    created_at: Vue.$vl_time.parse_as_local(audit.created_at),
  })
}

export const use_overtime = defineStore('overtime', {
  state: () => ({
    employments: [],
    current_overtime_employment: {},
  }),

  actions: {
    async fetch_employments_and_overtime_for_employee(employee) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/employee/${employee.id}/all`)
      this.employments = data.map(parse_employment)
    },

    async fetch_current_employment_and_overtime_for_employee(employee_id) {
      this.current_overtime_employment = {}

      const { data } = await Vue.smcb_axios.get(`${this.base_url}/employee/${employee_id}/current`)

      if (data) {
        this.current_overtime_employment = parse_employment(data, 0)
      }
    },

    async create_overtime_audit(audit) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/audit`, audit)
      const index = this.employments.findIndex(e => e.employment_id === data.employment_id)
      if (index > -1) {
        Vue.set(this.employments, index, parse_employment(data, index))
      }
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/overtime`,
    audits: state => state.employments.flatMap(e => e.overtime_audits),
  },
})
