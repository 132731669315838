import Vue from 'vue'

import { defineStore } from 'pinia'
import { use_payments } from './payments_store'

export const use_reports = defineStore('reports', {
  state: () => ({
    all: [],
  }),

  actions: {
    async fetch() {
      const payments = use_payments()

      const response = await Vue.smcb_axios.get(`${payments.base_url}/reports`)
      if (response.status === 200) {
        this.all = response.data
      }
    },

    async trigger_generation({ from, to, type, include_receipts, locale }) {
      const payments = use_payments()

      try {
        const response = await Vue.smcb_axios.post(`${payments.base_url}/generate_report`, { from, to, type, include_receipts, locale })
        if (response.status === 201) {
          let result = true
          if (this.all.map(r => r.report_id).includes(response.data.report_id)) {
            result = 'duplicate'
          } else {
            this.all.push(response.data)
          }
          return result
        }
        return false
      } catch (error) {
        return false
      }
    },
  },
})
