import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

function parse_ss(ss) {
  ss.first_booking = Vue.$vl_time.parse_as_local(ss.first_booking)
  ss.last_booking = Vue.$vl_time.parse_as_local(ss.last_booking)
  return ss
}

export const use_services_stats = defineStore('services_stats', {
  state: () => ({
    fr: null,
    to: null,
    stats_by_service_id: {},
  }),

  actions: {
    async load(date_path) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/checkins/service-stats/${date_path}`
      const { data } = await Vue.smcb_axios.get(url)

      data.stats.forEach(ss => Vue.set(this.stats_by_service_id, ss.service.id, parse_ss(ss)))
      this.fr = Vue.$vl_time.parse_as_local(data.fr)
      this.to = Vue.$vl_time.parse_as_local(data.to)
    },
  },

  getters: {
    services_stats: state => Vue.$vl_utils.sort_by(Object.values(state.stats_by_service_id), s => s.service.id),
  },
})
