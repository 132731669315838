import { defineStore } from 'pinia'
import { use_entry_point } from './entry_point_store'
import { use_gym_modules } from './gym_modules_store'
import { use_services } from './services_store'
import { use_video_consultation } from './video_consultation_store'
import Vue from 'vue'

const all_roles = Object.freeze(['employee', 'editor', 'admin'])

const default_feature_settings = Object.freeze({
  log_tasks: {
    employee: {
      off: {
        disabled: true,
      },
      view: {
        value: false,
        is_default: true,
      },
      manage: {
        value: true,
      },
    },
    editor: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
    admin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
    superadmin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
  },
  full_calendar: {
    employee: {
      off: {
        value: false,
        is_default: true,
      },
      view: {
        value: true,
      },
      manage: {
        disabled: true,
      },
    },
    editor: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
    admin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
    superadmin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
  },
  wages: {
    employee: {
      off: {
        value: false,
        is_default: true,
      },
      view: {
        value: true,
      },
      manage: {
        disabled: true,
      },
    },
    editor: {
      off: {
        value: false,
        is_default: true,
      },
      view: {
        value: true,
      },
      manage: {
        disabled: true,
      },
    },
    admin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
    superadmin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: true,
        is_default: true,
        disabled: true,
      },
    },
  },
  absences: {
    employee: {
      off: {
        value: 'off',
        disabled: true,
        is_default: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        disabled: true,
      },
    },
    editor: {
      off: {
        value: 'off',
        is_default: true,
      },
      view: {
        value: 'view',
      },
      manage: {
        value: 'manage',
      },
    },
    admin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: 'manage',
        disabled: true,
        is_default: true,
      },
    },
    superadmin: {
      off: {
        disabled: true,
      },
      view: {
        disabled: true,
      },
      manage: {
        value: 'manage',
        disabled: true,
        is_default: true,
      },
    },
  },
  video_consultation: {
    employee: {
      off: {
        is_default: true,
        value: 'off',
      },
      view_manage: {
        value: 'view',
      },
    },
    editor: {
      off: {
        is_default: true,
        value: 'off',
      },
      view_manage: {
        value: 'view',
      },
    },
    admin: {
      off: {
        is_default: true,
        value: 'off',
      },
      view_manage: {
        value: 'view',
      },
    },
    superadmin: {
      off: {
        is_default: true,
        value: 'off',
      },
      view_manage: {
        value: 'view',
      },
    },
  },
  routesetter: {
    employee: {
      off: {
        value: 'off',
      },
      view: {
        value: 'view',
        is_default: true,
      },
      manage: {
        value: 'manage',
      },
    },
    editor: {
      off: {
        value: 'off',
      },
      view: {
        value: 'view',
        is_default: true,
      },
      manage: {
        value: 'manage',
      },
    },
    admin: {
      off: {
        value: 'off',
      },
      view: {
        value: 'view',
        is_default: true,
      },
      manage: {
        value: 'manage',
      },
    },
    superadmin: {
      off: {
        value: 'off',
      },
      view: {
        value: 'view',
      },
      manage: {
        value: 'manage',
        is_default: true,
      },
    },
  },
})

function get_default_permission_for_role(settings, role, key) {
  const role_settings = settings[key][role]

  // The 'default' flag is mandatory
  for (const [permission, setting] of Object.entries(role_settings)) {
    if (setting && setting.is_default) return permission
  }

  console.warn('No default for role permission defined', role_settings)
  return null
}

function get_default_permission_value_for_role(settings, role, key) {
  const default_permission = get_default_permission_for_role(settings, role, key)
  if (default_permission) return settings[key][role][default_permission].value
  return null
}

function has_permission_option(settings, role, key, permission) {
  return !!settings[key][role][permission]
}

function get_permission_value(settings, role, key, permission) {
  const permission_settings = settings[key][role][permission]
  if ('value' in permission_settings) return permission_settings.value
  return null
}

function is_permission_checked(settings, role, employee_permission, key, permission) {
  const permission_settings = settings[key][role][permission]
  if (!permission_settings) return false

  // If value is not present (null or undefined) then radio boxes fallback to the default value for role
  if (employee_permission == null) {
    const def = get_default_permission_value_for_role(settings, role, key)
    return def === permission_settings.value
  }

  return permission_settings.value === employee_permission
}

function is_checkbox_disabled(settings, role, key, permission) {
  const permission_settings = settings[key][role][permission]
  if (!permission_settings) return true
  return permission_settings.disabled
}

function is_permission_disabled_for_key(settings, permission, key) {
  return all_roles.every(role => settings[key][role].disabled)
}

export const use_permissions = defineStore('permissions', {
  getters: {
    roles() {
      return Object.freeze(['employee', 'editor', 'admin'])
    },

    service_settings() {
      const active_services = use_services().active_and_sorted

      const entries = active_services.map(service => {
        const title = service.title_i18n[I18n.locale] || service.title_i18n.en || Vue.$i18n.t(`services.names.${service.name}`)

        return [
          service.name,
          {
            type: 'service',
            service_id: service.id,
            name: service.name,
            title,
            employee: {
              off: {
                is_default: true,
                value: 'off',
              },
              view: {
                value: 'view',
              },
              manage: {
                disabled: true,
              },
            },
            editor: {
              off: {
                value: 'off',
              },
              view: {
                is_default: true,
                value: 'view',
              },
              manage: {
                value: 'manage',
              },
            },
            admin: {
              off: {
                value: 'off',
              },
              view: {
                value: 'view',
              },
              manage: {
                value: 'manage',
                is_default: true,
              },
            },
            superadmin: {
              off: {
                disabled: true,
              },
              view: {
                disabled: true,
              },
              manage: {
                value: 'manage',
                is_default: true,
                disabled: true,
              },
            },
          },
        ]
      })

      return Object.freeze(Object.fromEntries(entries))
    },

    feature_settings() {
      const wages_enabled = use_gym_modules().wages_enabled
      const video_consultation_enabled = use_video_consultation().gym_can_have_video_consultations

      const visible_entries = Object.entries(default_feature_settings).filter(([k]) => {
        if (k === 'absences' && !wages_enabled) return false
        if (k === 'wages' && !wages_enabled) return false
        if (k === 'video_consultation' && !video_consultation_enabled) return false
        if (k === 'routesetter' && !this.show_routesetter) return false
        return true
      })

      return Object.freeze(Object.fromEntries(visible_entries))
    },

    feature_settings_for_role_change() {
      const feature_settings = structuredClone(this.feature_settings)
      delete feature_settings.video_consultation
      return feature_settings
    },

    show_routesetter() {
      return Vue.$vl_utils.is_running_gyms()
    },
  },

  actions: {
    get_feature_default_permission_value_for_role(role, feature) {
      return get_default_permission_value_for_role(this.feature_settings, role, feature)
    },

    get_service_default_permission_value_for_role(role, service) {
      return get_default_permission_value_for_role(this.service_settings, role, service)
    },

    has_feature_permission_option(role, feature, permission) {
      return has_permission_option(this.feature_settings, role, feature, permission)
    },

    has_service_permission_option(role, service, permission) {
      return has_permission_option(this.service_settings, role, service, permission)
    },

    get_feature_permission_value(role, feature, permission) {
      return get_permission_value(this.feature_settings, role, feature, permission)
    },

    get_service_permission_value(role, service, permission) {
      return get_permission_value(this.service_settings, role, service, permission)
    },

    is_feature_permission_checked(role, employee_permissions, feature, permission) {
      return is_permission_checked(this.feature_settings, role, employee_permissions[feature], feature, permission)
    },

    is_service_permission_checked(role, employee_services_permissions = {}, service, permission) {
      const service_permission = employee_services_permissions[service]
      if (!service_permission) return false
      return is_permission_checked(this.service_settings, role, service_permission.value, service, permission)
    },

    is_feature_checkbox_disabled(role, feature, permission) {
      return is_checkbox_disabled(this.feature_settings, role, feature, permission)
    },

    is_service_checkbox_disabled(role, service, permission) {
      return is_checkbox_disabled(this.service_settings, role, service, permission)
    },

    is_permission_disabled_for_feature(permission, feature) {
      return is_permission_disabled_for_key(this.feature_settings, permission, feature)
    },

    is_permission_disabled_for_service(permission, service) {
      return is_permission_disabled_for_key(this.service_settings, permission, service)
    },
  },
})
