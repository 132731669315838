import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_absences } from './absences_store'
import { use_actor } from './actor_store'
import { use_calendar_employees } from './calendar_employees_store'
import { use_entry_point } from './entry_point_store'
import { use_public_holidays } from './public_holidays_store'
import { use_services } from './services_store'
import { use_shift_planning } from './shift_planning_store'
import { use_slot_defs } from './slot_defs_store'
import { use_smcb_gym } from './smcb_gym_store'
import { use_task_groups } from './task_groups_store'
import { use_tasks } from './tasks_store'
import { use_working_hours } from './working_hours_store'

function clean_calendar_filters(filter, existing_employee_ids, existing_task_group_ids) {
  filter.show_these_services = filter.show_these_services || []
  filter.active_employees = (filter.active_employees || []).filter(id => existing_employee_ids.includes(id))
  filter.active_task_groups = (filter.active_task_groups || []).filter(id => existing_task_group_ids.includes(id))
  return filter
}

export const use_calendar_filter = defineStore('calendar_filter', {
  state: () => ({
    active_task_groups: [],
    employee_ids: [],
    status: 'all',
    stored_filters: [],

    show_only_public: false,
    show_only_open: false,
    show_only_unpublished: false,
    show_only_public_holidays: false,
    show_only_slot_defs: false,
    show_only_vacations: false,
    show_only_sick_leaves: false,
    show_only_tasks: false,
    show_only_courses: false,
    show_only_working_hours: false,
    show_opening_hours: true,
    show_these_services: [],
  }),

  actions: {
    batch_set_filters(filters) {
      this.active_task_groups = filters.active_task_groups || []
      this.employee_ids = filters.active_employees || []
      this.show_only_open = filters.show_only_open || false
      this.show_only_public = filters.show_only_public || false
      this.show_only_public_holidays = filters.show_only_public_holidays || false
      this.show_only_sick_leaves = filters.show_only_sick_leaves || false
      this.show_only_slot_defs = filters.show_only_slot_defs || false
      this.show_only_tasks = filters.show_only_tasks || false
      this.show_only_courses = filters.show_only_courses || false
      this.show_only_unpublished = filters.show_only_unpublished || false
      this.show_only_vacations = filters.show_only_vacations || false
      this.show_only_working_hours = filters.show_only_working_hours || false
      this.show_opening_hours = filters.show_opening_hours || false
      this.show_these_services = filters.show_these_services || []
    },

    replace_active_task_groups({ task_group_ids, router }) {
      let ids = [...task_group_ids].sort()
      this.set_active_task_groups(ids)
      ids = ids.join(',') || null
      router.replace({ params: { task_group_ids: ids } })
    },

    set_active_task_groups(active_task_groups) {
      this.active_task_groups = [...active_task_groups].sort()
    },

    save_filters_to_local_store(stored_filter) {
      if (stored_filter) {
        localStorage.setItem(this.storage_key, JSON.stringify({ stored_filter_id: stored_filter.id }))
      } else {
        localStorage.setItem(this.storage_key, JSON.stringify(this.active_filters))
      }
    },

    load_filters_from_local_storage() {
      const filters_json = localStorage.getItem(this.storage_key)
      if (!filters_json) return

      let filters = JSON.parse(filters_json)
      if (filters.stored_filter_id) {
        filters = this.stored_filters.find(f => f.id === filters.stored_filter_id) || filters
      } else {
        const task_group_ids = use_task_groups().active.map(tg => tg.id)
        const employee_ids = use_calendar_employees().filterable_employees.map(e => e.id)
        filters = clean_calendar_filters(filters, employee_ids, task_group_ids)
      }

      this.batch_set_filters(filters)
    },

    async fetch_stored_filters() {
      const { data } = await Vue.smcb_axios.get(this.base_url)
      const task_group_ids = use_task_groups().active.map(tg => tg.id)
      const employee_ids = use_calendar_employees().filterable_employees.map(e => e.id)
      const filters = data
        .map(f => clean_calendar_filters(f, employee_ids, task_group_ids))
        .filter(
          f =>
            f.show_only_open ||
            f.show_only_public ||
            f.show_only_unpublished ||
            f.show_only_public_holidays ||
            f.show_only_slot_defs ||
            f.show_only_vacations ||
            f.show_only_sick_leaves ||
            f.show_only_tasks ||
            f.show_only_courses ||
            f.show_only_working_hours ||
            f.show_opening_hours ||
            f.show_these_services.length > 0 ||
            f.active_employees.length > 0 ||
            f.active_task_groups.length > 0
        )

      this.stored_filters = filters
    },

    async create_stored_filter(name) {
      const { data } = await Vue.smcb_axios.post(this.base_url, {
        name,
        show_only_public: this.show_only_public,
        show_only_open: this.show_only_open,
        show_only_public_holidays: this.show_only_public_holidays,
        show_only_slot_defs: this.show_only_slot_defs,
        show_these_services: this.show_these_services,
        show_only_vacations: this.show_only_vacations,
        show_only_sick_leaves: this.show_only_sick_leaves,
        show_only_unpublished: this.show_only_unpublished,
        show_only_tasks: this.show_only_tasks,
        show_only_courses: this.show_only_courses,
        show_only_working_hours: this.show_only_working_hours,
        show_opening_hours: this.show_opening_hours,
        task_group_ids: this.active_task_groups,
        employee_ids: this.employee_ids,
      })

      this.stored_filters = [...this.stored_filters, data]
    },

    async delete_stored_filter(filter_id) {
      await Vue.smcb_axios.delete(`${this.base_url}/${filter_id}`)

      Vue.delete(
        this.stored_filters,
        this.stored_filters.findIndex(cp => cp.id === filter_id)
      )
    },

    async update_stored_filter({ filter_id, name }) {
      const { data } = await Vue.smcb_axios.patch(`${this.base_url}/${filter_id}`, { name })

      Vue.set(
        this.stored_filters,
        this.stored_filters.findIndex(cp => cp.id === data.id),
        data
      )
    },

    set_employee_ids(employee_ids) {
      this.employee_ids = [...employee_ids].sort()
    },
  },

  getters: {
    base_url: () => {
      return `${use_smcb_gym().base_url}/calendar_filters`
    },

    active_filters: state => {
      return {
        active_employees: state.employee_ids,
        active_task_groups: state.active_task_groups,
        show_only_open: state.show_only_open,
        show_only_public_holidays: state.show_only_public_holidays,
        show_only_public: state.show_only_public,
        show_only_sick_leaves: state.show_only_sick_leaves,
        show_only_slot_defs: state.show_only_slot_defs,
        show_only_tasks: state.show_only_tasks,
        show_only_courses: state.show_only_courses,
        show_only_unpublished: state.show_only_unpublished,
        show_only_vacations: state.show_only_vacations,
        show_only_working_hours: state.show_only_working_hours,
        show_opening_hours: state.show_opening_hours,
        show_these_services: state.show_these_services,
      }
    },

    all_filters_disabled: state =>
      [
        state.show_only_working_hours,
        state.show_only_tasks,
        state.show_only_courses,
        state.show_only_public_holidays,
        state.show_only_slot_defs,
        state.show_only_vacations,
        state.show_only_sick_leaves,
      ].every(f => f === false),

    is_working_hours_hidden: state =>
      function (wh) {
        if (!this.all_filters_disabled && !state.show_only_working_hours) return true
        if (state.show_only_open || state.show_only_unpublished || state.show_only_public) return true
        if (!this.active_employee_ids_for_events.includes(wh.employee_id)) return true
        return false
      },

    filtered_working_hours() {
      return Object.values(use_working_hours().calendar_working_hours)
        .flat()
        .filter(wh => !this.is_working_hours_hidden(wh))
    },

    is_working_hours_log_hidden: state =>
      function (whl) {
        if (!this.all_filters_disabled && !state.show_only_working_hours) return true
        if (state.show_only_open || state.show_only_unpublished || state.show_only_public) return true
        if (!this.active_employee_ids_for_events.includes(whl.employee_id)) return true
        if (whl.mute) return true
        if (state.active_task_groups.length > 0 && !state.active_task_groups.includes(whl.task_group_id)) return true
        return false
      },

    filtered_working_hours_logs() {
      return use_working_hours().working_hours_logs.filter(whl => !this.is_working_hours_log_hidden(whl))
    },

    is_slot_def_hidden: state =>
      function (sd) {
        if (!this.all_filters_disabled && !state.show_only_slot_defs) return true
        if (!sd.open_to_booking) return true

        // No service filters active
        if (state.show_these_services.length === 0) return false

        // Service with area defined
        if (sd.slot_area_id) return !state.show_these_services.includes(`${sd.service_id}_${sd.slot_area_id}`)

        // Null area can match two different filters: "others"
        const others = state.show_these_services.includes(`${sd.service_id}_others`)
        if (others) return false

        // Or unruled service
        return !state.show_these_services.includes(sd.service_id.toString())
      },

    is_absence_hidden: () =>
      function (a) {
        return !this.active_employee_ids_for_events.includes(a.employee_id)
      },

    is_vacation_hidden: state =>
      function (v) {
        if (!this.all_filters_disabled && !state.show_only_vacations) return true
        return this.is_absence_hidden(v)
      },

    filtered_vacations() {
      return use_absences().vacations.filter(v => !this.is_vacation_hidden(v))
    },

    is_sick_leave_hidden: state =>
      function (v) {
        if (!this.all_filters_disabled && !state.show_only_sick_leaves) return true
        return this.is_absence_hidden(v)
      },

    filtered_sick_leaves() {
      return use_absences().sick_leaves.filter(sl => !this.is_sick_leave_hidden(sl))
    },

    filtered_public_holidays(state) {
      if (!this.all_filters_disabled && !state.show_only_public_holidays) return []
      return use_public_holidays().all
    },

    filtered_slot_defs() {
      const ids = use_services().active.reduce((sum, x) => ({ ...sum, [x.id]: true }), {})
      return use_slot_defs().all.filter(sr => !this.is_slot_def_hidden(sr) && ids[sr.service_id])
    },

    storage_key: () => `calendar-filters-${use_entry_point().gym.id}`,

    active_employee_ids_for_events: state => {
      if (state.employee_ids.length > 0) return state.employee_ids
      return use_calendar_employees().filterable_employees.map(e => e.id)
    },

    filtered_tasks() {
      return use_tasks().active.filter(task => !this.is_task_hidden(task))
    },

    filtered_courses() {
      return use_tasks().courses.filter(course => !this.is_course_hidden(course))
    },

    is_task_hidden: state =>
      function (task) {
        if (!this.all_filters_disabled && !state.show_only_tasks) return true

        // hide unpublished tasks for simple employees (when they got loaded before the shift started)
        const shift_planning = use_shift_planning()
        if (use_actor().employee.role === 'employee' && shift_planning.is_task_unpublished(task)) return true

        // on employees filters
        const employee_ids = new Set(state.employee_ids)
        if (employee_ids.size && !task.employee_ids.some(id => employee_ids.has(id))) return true

        // on status filters
        if (state.show_only_public && !task.public) return true
        if (state.show_only_open && !(task.required_employees > 0 && task.employee_ids.length < task.required_employees)) return true
        if (state.show_only_unpublished && !shift_planning.is_task_unpublished(task)) return true

        // on task group filters
        if (state.active_task_groups.length) {
          const task_group = use_task_groups().task_group_from_id(task.task_group_id)
          const is_active = state.active_task_groups.length === 0 || state.active_task_groups.includes(task.task_group_id)
          if (!task_group?.show_on_calendar) return true
          if (!is_active) return true
        }

        return false
      },

    is_course_hidden: state =>
      function (task) {
        if (!this.all_filters_disabled && !state.show_only_courses) return true

        // hide unpublished tasks for simple employees (when they got loaded before the shift started)
        const shift_planning = use_shift_planning()
        if (use_actor().employee.role === 'employee' && shift_planning.is_task_unpublished(task)) return true

        // on employees filters
        const employee_ids = new Set(state.employee_ids)
        if (employee_ids.size && !task.employee_ids.some(id => employee_ids.has(id))) return true

        // on status filters
        if (state.show_only_public && !task.public) return true
        if (state.show_only_open && !(task.required_employees > 0 && task.employee_ids.length < task.required_employees)) return true
        if (state.show_only_unpublished && !shift_planning.is_task_unpublished(task)) return true

        // on task group filters
        if (state.active_task_groups.length) {
          const task_group = use_task_groups().task_group_from_id(task.task_group_id)
          const is_active = state.active_task_groups.length === 0 || state.active_task_groups.includes(task.task_group_id)
          if (!task_group?.show_on_calendar) return true
          if (!is_active) return true
        }

        // on courses filter
        if (state.show_only_courses) {
          if (task.is_course === false) return true
        }

        return false
      },
  },
})
