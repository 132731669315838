import { defineStore } from 'pinia'

export const use_reservations_current_booking = defineStore('reservations_current_booking', {
  state: () => ({
    data: {
      allow_overbooking: false,
      allow_overbooking_on_this_area: null,
      show_docs: false,
      slot: null,
      selected_participant_id: null,
    },
  }),

  actions: {
    clear() {
      this.data.slot = null
      this.data.selected_participant_id = null
    },

    set({ slot, selected_participant_id, allow_overbooking, show_docs }) {
      this.data.slot = slot
      this.data.selected_participant_id = selected_participant_id || null
      this.data.allow_overbooking = !!allow_overbooking
      this.data.allow_overbooking_on_this_area = null
      this.data.show_docs = show_docs || false
    },
  },
})
