import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_entry_point } from './entry_point_store'
import { use_employee_basic } from './employee_basic_store'

// TODO: merge into other files (e.g. employee_basic_store)
export const use_employees = defineStore('employees', {
  actions: {
    async add_superadmin({ email, vl_employee }) {
      const { data } = await Vue.smcb_axios.post(`${use_entry_point().fred_base_url}/manage/add-superadmin`, { email, vl_employee })
      use_employee_basic().manage_superadmin({ ...data.employee, email: email })
    },

    async invite_superadmin(user) {
      const { data } = await Vue.smcb_axios.post(`${use_entry_point().fred_base_url}/manage/invite-superadmin`, {
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
      })

      use_employee_basic().manage_superadmin({ ...data.employee, email: user.email })
    },

    async remove_superadmin(employee) {
      const { data } = await Vue.smcb_axios.patch(`${use_entry_point().fred_base_url}/manage/remove-superadmin/${employee.vl_gym_user_id}`)
      use_employee_basic().manage_superadmin(data.employee)
    },

    async toggle_vl_employee({ employee, vl_employee }) {
      await Vue.smcb_axios.patch(`${use_entry_point().fred_base_url}/manage/toggle-vl-employee/${employee.vl_gym_user_id}`, { vl_employee })
    },
  },
})
