import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_smcb_gym } from '@core/entry_point/stores/smcb_gym_store'

const date_format_str = 'yyyy-MM-dd'

function parse_working_hours(whs) {
  return {
    ...whs,
    start_date: !whs.start_date ? null : DateTime.fromFormat(whs.start_date, date_format_str),
    end_date: !whs.end_date ? null : DateTime.fromFormat(whs.end_date, date_format_str),
    working_hours_intervals: whs.working_hours_intervals.map(i => parse_working_hours_interval(i)),
  }
}

function parse_working_hours_log(working_hours_log) {
  return {
    ...working_hours_log,
    start: Vue.$vl_time.parse_as_local(working_hours_log.start),
    end: Vue.$vl_time.parse_as_local(working_hours_log.end),
  }
}

function parse_working_hours_interval(interval) {
  return {
    ...interval,
    start_time: Vue.$vl_time.parse_as_local(interval.start_time),
    end_time: Vue.$vl_time.parse_as_local(interval.end_time),
  }
}

function serialize_working_hours_interval_for_server(interval) {
  return {
    ...interval,
    start_time: interval.start_time.toFormat('HH:mm'),
    end_time: interval.end_time.toFormat('HH:mm'),
  }
}

export const use_working_hours = defineStore('working_hours', {
  state: () => ({
    employee_working_hours: {},
    calendar_working_hours: {},
    working_hours_logs: [],
  }),

  actions: {
    add_or_update_working_hours_log(whl) {
      this.working_hours_logs = this.working_hours_logs.filter(l => l.id !== whl.id)
      this.working_hours_logs.push(parse_working_hours_log(whl))
    },

    async create_or_update_working_hours_log(working_hours_log) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/working_hours_log`, working_hours_log)
      this.add_or_update_working_hours_log(data)
    },

    set_calendar_working_hours(working_hours) {
      this.calendar_working_hours = {}
      working_hours.forEach(wh => {
        const employee_wh = this.calendar_working_hours[`${wh.employee_id}`] || []
        Vue.set(this.calendar_working_hours, wh.employee_id, [...employee_wh, parse_working_hours(wh)])
      })
    },

    async fetch_employee_working_hours(employee_id) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/employee/${employee_id}`)
      Vue.set(this.employee_working_hours, employee_id, parse_working_hours(data))
    },

    async update_employee_working_hours({ employee_id, working_hours, working_hours_intervals }) {
      working_hours_intervals = working_hours_intervals.map(whi => serialize_working_hours_interval_for_server(whi))
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/employee/${employee_id}`, { working_hours, working_hours_intervals })
      Vue.set(this.employee_working_hours, employee_id, parse_working_hours(data))
    },

    async fetch_working_hours({ start, end }) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/gym`, { start, end })
      this.set_calendar_working_hours(data.working_hours)
      this.working_hours_logs = data.working_hours_logs.map(whl => parse_working_hours_log(whl))
    },

    restrict_working_hours_log(whl) {
      this.working_hours_logs = this.working_hours_logs.filter(l => l.id !== whl.id)
    },

    async delete_working_hours_log(working_hours_log) {
      const { data } = await Vue.smcb_axios.delete(`${this.base_url}/working_hours_log`, { data: { working_hours_log } })
      if (working_hours_log.id) this.restrict_working_hours_log(working_hours_log)
      if (data.id) this.add_or_update_working_hours_log(data)
    },

    async fetch_employee_ids_for_date(date) {
      const { data } = await Vue.smcb_axios.post(`${this.base_url}/employees_for_date`, { date })
      return data.employee_ids || []
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/working_hours`,
    working_hours_by_id: state => id =>
      Object.entries(state.calendar_working_hours)
        .flatMap(([_, whs]) => whs)
        .find(wh => wh.id === id),
  },
})
