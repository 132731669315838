import { defineStore } from 'pinia'
import Vue from 'vue'
import { use_smcb_gym } from '@core/entry_point/stores/smcb_gym_store'
import { use_actor } from '@core/entry_point/stores/actor_store'

export const use_shift_planning = defineStore('shift_planning', {
  state: () => ({
    current: {},
    session_ongoing: false,
  }),

  actions: {
    async load_current() {
      if (!this.enabled) return
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/shift_plannings/current`)
      this.current = data
    },

    async publish() {
      if (!this.enabled) return
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.post(`${smcb_gym.base_url}/shift_plannings/current/publish`)
      this.current = data
      this.session_ongoing = false
    },

    async load_session() {
      if (!this.enabled) return
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/shift_plannings/session`)
      this.session_ongoing = data.ongoing
    },

    async start() {
      if (!this.enabled) return

      try {
        const smcb_gym = use_smcb_gym()
        this.session_ongoing = true
        await Vue.smcb_axios.post(`${smcb_gym.base_url}/shift_plannings/session/start`)
      } catch (e) {
        this.session_ongoing = false
        throw e
      }
    },

    async end() {
      if (!this.enabled) return

      try {
        const smcb_gym = use_smcb_gym()
        this.session_ongoing = false
        await Vue.smcb_axios.post(`${smcb_gym.base_url}/shift_plannings/session/end`)
      } catch (e) {
        this.session_ongoing = true
        throw e
      }
    },
  },

  getters: {
    enabled: () => use_actor().can_editor,
    is_task_unpublished: state => task => Array.isArray(state.current.unpublished_task_ids) && state.current.unpublished_task_ids.includes(task.id),
  },
})
