import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_smcb_gym } from './smcb_gym_store'

export const use_private_urls = defineStore('private_urls', {
  actions: {
    async generate_private_checkins_url({ times, first_day, area_id }) {
      const origin = window.location.origin
      const request_url = `${use_smcb_gym().base_url}/checkins/private-checkins/url`
      const response = await Vue.smcb_axios.post(request_url, { times, first_day, origin, area_id })
      return response.data.url
    },

    async copy_private_checkins_url({ times, first_day, i18n, area_id }) {
      try {
        const url = await this.generate_private_checkins_url({ times, first_day, area_id })
        await navigator.clipboard.writeText(url)
        Vue.$vl_utils.show_default_alert(i18n.t('form.copied_to_clipboard'))
        return true
      } catch (e) {
        Vue.$vl_utils.show_error_alert(i18n.t('form.error_copying_to_clipboard'), e)
        return false
      }
    },
  },
})
