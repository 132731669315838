import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_services } from './services_store'
import { use_smcb_gym } from './smcb_gym_store'

export const use_vax_overview = defineStore('vax_overview', {
  state: () => ({
    age_slice_counters: {
      old_folk: {},
      underage: {},
    },
    current_day: null,
    loading: false,
    presences: [],
    daily_stock: {},
    vaccine_info_counters: {},
    vaccine_info_totals: {
      total_participants: 0,
      total_vaccinated: 0,
    },
    current_slot_defs_with_areas: null,
    show_canceled_participants: true,
  }),

  actions: {
    set_aggregated_counters({ vaccine_info_counters, underage, old_folk, total_participants, total_vaccinated }) {
      this.vaccine_info_counters = vaccine_info_counters
      this.age_slice_counters.underage = underage
      this.age_slice_counters.old_folk = old_folk
      this.vaccine_info_totals.total_participants = total_participants
      this.vaccine_info_totals.total_vaccinated = total_vaccinated
    },

    set_stock_for_day({ daily_stock, for_date }) {
      if (daily_stock == null) {
        this.daily_stock = {
          data: {},
          for_date: for_date,
        }
      } else {
        this.daily_stock = daily_stock
      }
    },

    update_presence_vaccine({ id, vaccine }) {
      const presence = this.presences.find(p => p.participant_id === id)
      Vue.set(presence, 'vaccine', vaccine)
    },

    cancel_presence(participant_id) {
      const presence = this.presences.find(p => p.participant_id === participant_id)
      Vue.set(presence, 'status', 'canceled')
    },

    async clear_one_extra_field_of_participant({ participant_id, key }) {
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant_id}/extra-fields/${key}`
      await Vue.smcb_axios.delete(url)
    },

    async set_one_extra_field_of_participant({ participant_id, key, value }) {
      const url = `${use_smcb_gym().base_url}/checkins/participants/${participant_id}/extra-fields/${key}`
      await Vue.smcb_axios.patch(url, { value })
    },

    async silently_fetch_vax_overview_presences() {
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      const url = `${use_services().base_url_of_selected}/checkins/vax-overview/presences/${date_path}`
      const { data } = await Vue.smcb_axios.get(url)
      this.presences = data.presences
      this.set_stock_for_day({ daily_stock: data.vax_daily_stock, for_date: date.toFormat('yyyy-MM-dd') })
      this.current_slot_defs_with_areas = data.slot_defs
    },

    async silently_fetch_vax_aggregate({ date_path }) {
      const url = `${use_services().base_url_of_selected}/checkins/vax-overview/aggregate/${date_path}`
      const { data } = await Vue.smcb_axios.get(url)
      this.set_aggregated_counters(data)
    },

    async fetch_vax_overview_presences_for_date({ date }) {
      this.loading = true
      this.current_day = date
      await this.silently_fetch_vax_overview_presences()
      this.loading = false
    },

    async fetch_vax_aggregate_for_date({ date }) {
      this.loading = true
      this.current_day = date
      const date_path = date.toFormat('yyyy/MM/dd')
      await this.silently_fetch_vax_aggregate({ date_path })
      this.loading = false
    },

    async fetch_vax_aggregate_for_month({ month }) {
      this.loading = true
      this.current_day = DateTime.fromFormat(month, 'yyyy/MM')
      await this.silently_fetch_vax_aggregate({ date_path: month })
      this.loading = false
    },

    async update_vax_daily_stock(vax_daily_stock) {
      const date = this.current_day
      const date_path = date.toFormat('yyyy/MM/dd')
      const { data } = await Vue.smcb_axios.patch(`${use_services().base_url_of_selected}/checkins/vax-daily-stocks/${date_path}`, { vax_daily_stock })
      this.set_stock_for_day({ daily_stock: data })
    },
  },
})
