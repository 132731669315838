import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_smcb_gym } from './smcb_gym_store'

export const use_staff_finance = defineStore('staff_finance_store', {
  state: () => ({
    staff_finance: [],
    yearly_overview: {},
    overview: {},
    comparison: {},
  }),

  actions: {
    async fetch({ from, to, include_future_assignments }) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/staff`, { params: { from, to, include_future_assignments } })
      this.staff_finance = data
    },

    async fetch_yearly_overview() {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/yearly_overview`)
      this.yearly_overview = data
    },

    async fetch_overview({ from, to, include_future_assignments }) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/overview`, { params: { from, to, include_future_assignments } })
      this.overview = data
    },

    async fetch_comparison({ from, to, compare_from, compare_to, include_future_assignments }) {
      const { data } = await Vue.smcb_axios.get(`${this.base_url}/compare`, {
        params: { from, to, compare_from, compare_to, include_future_assignments },
      })
      this.comparison = data
    },
  },

  getters: {
    base_url: () => `${use_smcb_gym().base_url}/finance`,
  },
})
