import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

export function parse_fat_participant(participant) {
  if (participant) {
    participant.checked_in_at = Vue.$vl_time.parse_as_local(participant.checked_in_at)
    participant.must_exit_at = Vue.$vl_time.parse_as_local(participant.must_exit_at)
    participant.checked_out_at = Vue.$vl_time.parse_as_local(participant.checked_out_at)
    participant.arrival_at = Vue.$vl_time.parse_as_local(participant.arrival_at)
  }

  return participant
}

export const use_fat_objects_for_modal = defineStore('fat_objects_for_modal', {
  state: () => ({
    current_fat_participant: null,
    current_fat_participant_id: null,
    current_fat_customer: null,
    loading_fat_participant: false,
    saving: false,
  }),

  actions: {
    async fetch_fat_participant(id) {
      id = parseInt(id)
      this.loading_fat_participant = true
      this.current_fat_participant = null
      this.current_fat_participant_id = id

      const { data } = await Vue.smcb_axios.get(`${use_smcb_gym().base_url}/fat_participants/${id}`)
      const { participant, customer } = data

      if (participant.id && participant.id === this.current_fat_participant_id) {
        this.current_fat_customer = customer
        this.current_fat_participant = parse_fat_participant(participant)
      }

      this.loading_fat_participant = false
    },
  },
})
