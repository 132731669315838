import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_smcb_gym } from './smcb_gym_store'

function process_incoming_warning(warning) {
  warning.valid_fr = Vue.$vl_time.parse_as_local(warning.valid_fr)
  warning.valid_to = Vue.$vl_time.parse_as_local(warning.valid_to)
  return warning
}

export const use_popup_warnings_manager = defineStore('popup_warnings_manager', {
  state: () => ({
    all: [],
  }),

  actions: {
    async load() {
      const { data } = await Vue.smcb_axios.get(`${use_smcb_gym().base_url}/popup_warnings`)
      this.all = data.map(process_incoming_warning)
    },

    async create(warning) {
      const { data } = await Vue.smcb_axios.post(`${use_smcb_gym().base_url}/popup_warnings`, warning)
      this.all = data.map(process_incoming_warning)
    },

    async delete(warning_id) {
      const { data } = await Vue.smcb_axios.delete(`${use_smcb_gym().base_url}/popup_warnings/${warning_id}`)
      this.all = data.map(process_incoming_warning)
    },
  },
})
