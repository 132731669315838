import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'
import { use_services } from './services_store'
import { use_visitors } from './visitors_store'

export const use_rki = defineStore('rki', {
  state: () => ({
    queue_count: -1,
    resubmitting: false,
  }),

  actions: {
    async post_request(url) {
      const smcb_gym = use_smcb_gym()
      try {
        const { data } = await Vue.smcb_axios.post(url)
        smcb_gym.settings = data

        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },

    async request_validation() {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/rki/request`

      return await this.post_request(url)
    },

    async validate() {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/rki/validate`

      return await this.post_request(url)
    },

    async revoke() {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/rki/revoke`

      return await this.post_request(url)
    },

    async fetch_retry_queue() {
      const services = use_services()
      const visitors = use_visitors()
      const url = `${services.base_url_of_selected}/rki/queue?date=${visitors.current_day}`

      const { data } = await Vue.smcb_axios.get(url)
      this.queue_count = data.queue_count
    },

    async retry() {
      this.resubmitting = true
      const services = use_services()
      const visitors = use_visitors()
      const url = `${services.base_url_of_selected}/rki/retry`

      await Vue.smcb_axios.post(url, { date: visitors.current_day })
      this.resubmitting = false
    },
  },

  getters: {
    enabled() {
      const smcb_gym = use_smcb_gym()
      const services = use_services()
      return services.of_selected.settings.uses_rki_api && smcb_gym.settings.rki?.validated
    },

    kill_switch() {
      const smcb_gym = use_smcb_gym()
      return smcb_gym.gym_options.rki_kill_switch
    },

    active() {
      return !this.kill_switch && this.enabled
    },

    elligible() {
      const services = use_services()
      return services.selected.context === 'germany-pharma' && ['vax-flu'].includes(services.selected.name)
    },
  },
})
