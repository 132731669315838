import { defineStore } from 'pinia'

export const use_participant_copypaste = defineStore('participant_copypaste', {
  actions: {
    copy(p) {
      localStorage.participant_clipboard_data = JSON.stringify({
        firstname: p.firstname,
        lastname: p.lastname,
        email: p.email,
        phone: p.phone,
        address: p.address,
        city: p.city,
        zip: p.zip,
        province: p.province,
        country: p.country,
        age: p.age,
        gender: p.gender,
        date_of_birth: p.date_of_birth,
        tax_id: p.tax_id,
        extra_fields: {
          document_type: p.extra_fields.document_type,
          id_document: p.extra_fields.id_document,
          document_city: p.extra_fields.document_city,
          document_date: p.extra_fields.document_date,
          health_card_number: p.extra_fields.health_card_number,
          health_card_expiration: p.extra_fields.health_card_expiration,
          kassennummer: p.extra_fields.kassennummer,
          kv_nummer: p.extra_fields.kv_nummer,
          health_insurance: p.extra_fields.health_insurance,
          health_insurance_status: p.extra_fields.health_insurance_status,
        },
      })
    },

    async paste() {
      if (this.is_present()) {
        let data = { ...JSON.parse(localStorage.participant_clipboard_data) }
        localStorage.removeItem('participant_clipboard_data')
        return data
      }
      // better to throw an error when trying to paste empty data?
      return null
    },

    // Must be a function (as it reads from local storage and not the store state)
    is_present() {
      return localStorage.participant_clipboard_data != null
    },
  },
})
