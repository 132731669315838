import axios from 'axios'
import Vue from 'vue'

import { defineStore } from 'pinia'
import { use_entry_point } from './entry_point_store'
import { use_smcb_gym } from './smcb_gym_store'

// helper function: generate a new file from base64 String
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}

export const use_gdpr_requests = defineStore('gdpr_requests', {
  state: () => ({
    gdpr_requests: [],
    participants: {},
    prescriptions: {},
  }),

  actions: {
    async load() {
      const { data } = await Vue.smcb_axios.get(`${use_smcb_gym().base_url}/checkins/gdpr-requests/requests-with-participants`)
      this.gdpr_requests = data.gdpr_requests
      this.participants = data.participants
      this.prescriptions = data.prescriptions
    },

    async process_request(id, is_accept) {
      const action = is_accept ? 'accept' : 'reject'
      const url = `${use_smcb_gym().base_url}/checkins/gdpr-requests/${action}`
      const { data } = await Vue.smcb_axios.patch(url, { id: id })
      const ix = this.gdpr_requests.findIndex(r => r.id == data.id)
      Vue.set(this.gdpr_requests, ix, data)
    },

    async search_customer(email) {
      const { data } = await Vue.smcb_axios.get(`${use_entry_point().fred_base_url}/manage/gdpr_customer_search?email=${encodeURIComponent(email)}`)
      return data || []
    },

    async anonymize_customer({ email, image }) {
      let data = {}

      if (image != null) {
        // generate file from base64 string
        const file = dataURLtoFile(image)
        // put file into form data
        data = new FormData()
        data.append('img', file, 'test.png')
      }

      const url = `${use_entry_point().fred_base_url}/manage/gdpr_customer_anonymize?email=${encodeURIComponent(email)}`
      const config = {
        headers: {
          'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content,
        },
      }

      try {
        const response = await axios.post(url, data, config)
        return response.data
      } catch {
        return null
      }
    },
  },

  getters: {
    all: state => {
      return Object.values(state.gdpr_requests)
    },
  },
})
