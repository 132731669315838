import Vue from 'vue'
import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'

export const use_resources = defineStore('resources', {
  state: () => ({
    by_id: {},
    services_ids: {},
  }),

  actions: {
    async load() {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.get(`${smcb_gym.base_url}/checkins/resources`)

      this.by_id = data.resources.reduce((h, x) => ({ [parseInt(x.id)]: x, ...h }), {})
      this.services_ids = data.services_ids
    },

    async create(resource) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.post(`${smcb_gym.base_url}/checkins/resources`, resource)

      Vue.set(this.by_id, data.resource.id, data.resource)
      Vue.set(this.services_ids, data.resource.id, data.services_ids)
    },

    async update(resource, services_ids) {
      const smcb_gym = use_smcb_gym()
      const { data } = await Vue.smcb_axios.patch(`${smcb_gym.base_url}/checkins/resources/${resource.id}`, { resource, services_ids })

      Vue.set(this.by_id, data.resource.id, data.resource)
      Vue.set(this.services_ids, data.resource.id, data.services_ids)
    },

    async delete(resource_id) {
      const smcb_gym = use_smcb_gym()
      await Vue.smcb_axios.delete(`${smcb_gym.base_url}/checkins/resources/${resource_id}`)

      Vue.delete(this.by_id, resource_id)
      Vue.delete(this.services_ids, resource_id)
    },
  },
})
