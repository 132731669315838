import { defineStore } from 'pinia'
import editable_mixin from './editable_mixin'
import Vue from 'vue'

export const use_pricings = defineStore('pricings', {
  state: () => ({
    ...editable_mixin.state(),
    in_edit: [],
  }),

  actions: {
    ...editable_mixin.actions(),

    new_empty_pricing() {
      let new_pricing = {
        name: '',
        details: '',
        price: 0,
        type: null,
        vat: 0,
        sku: null,
        active: true,
        max_allowed: null,
        account_identifier: null,
        tmp_id: Vue.$vl_utils.rand_alphanum(5),
      }

      this.update_edit(new_pricing)
    },
  },
})
