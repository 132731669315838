import Vue from 'vue'

import { defineStore } from 'pinia'
import { use_smcb_gym } from './smcb_gym_store'
import { use_devices } from './devices_store'

export const use_signatures = defineStore('signatures', {
  state: () => ({
    sign_process: {},
  }),

  actions: {
    async quit_sign_process(participant_id, participant_token) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/signatures/sign/${participant_id}/quit`
      Vue.smcb_axios.get(url)
      this.sign_process = { participant_token: participant_token, state: 'quit' }
    },

    async delete_signed_ticket(participant, doc_uuid) {
      const smcb_gym = use_smcb_gym()
      const url = `${smcb_gym.base_url}/signatures/sign/${participant.id}/${doc_uuid}`
      await Vue.smcb_axios.delete(url)
      this.sign_process = { participant_token: participant.token, state: 'deleted' }
    },

    async update_sign_process() {
      const devices = use_devices()
      if (devices.check_device_token()) {
        await devices.fetch_device()
        const device_state = devices.device.sign_state
        this.sign_process = device_state
      }
    },

    reload_page() {
      window.location = window.location.href.split('#')[0]
    },
  },

  getters: {
    get_gym_path() {
      return `${window.location.protocol}//${window.location.host}/${I18n.locale}`
    },

    get_sign_process_by_participant: state => participant => {
      return state.sign_process?.participant_token === participant.token ? state.sign_process?.state === 'signing' : false
    },

    get_sign_process_by_participant_doc: state => (participant, doc_uuid) => {
      if (state.sign_process?.participant_token !== participant.token) return false
      if (state.sign_process?.doc_uuid !== doc_uuid) return false
      return state.sign_process?.state === 'signing'
    },

    is_signing: state => {
      return state.sign_process?.state === 'signing'
    },
  },
})
